import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;
const API_URL_AUTH = process.env.REACT_APP_API_URL;
console.log(API_URL);
const apiInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    xsrfHeaderName: 'X-CSRFToken',
    xsrfCookieName: 'csrftoken'
});

const apiInstanceAuth = axios.create({
    baseURL: API_URL_AUTH,
    withCredentials: true,
    xsrfHeaderName: 'X-CSRFToken',
    xsrfCookieName: 'csrftoken'
});

export const postData = (endpoint, data, token) => apiInstance.post(endpoint, data, {
    headers: {
        'X-CSRFToken': token
    },
    timeout: 40 * 60 * 1000 // 40 minutes in milliseconds
});

export const postDataWithAuth = (endpoint, data, token, auth) => {
    // Log the arguments and headers
    console.log('Endpoint:', endpoint);
    console.log('Data:', data);
    console.log('Token:', token);
    console.log('Auth:', auth);

    // Prepare headers
    const headers = {
        'X-CSRFToken': token,
        'Authorization': `JWT ${auth}`,
        'content-type': 'multipart/form-data'
    };

    // Log headers
    console.log('Headers:', headers);

    // Make the POST request
    return apiInstance.post(endpoint, data, { headers, 
       timeout: 40 * 60 * 1000 /* 40 minutes in milliseconds */ });
};


export const getData = (endpoint) => apiInstance.get(endpoint);

export const getUserData = (endpoint, auth) => {
    console.log("get user date")
    console.log(endpoint)
    console.log(auth)
    return apiInstanceAuth.get(endpoint, {
        headers: {
            'Authorization': `JWT ${auth}`,
        }
    }).then(response => {
        console.log(response)
        if (response.status !== 200) {
            throw new Error('Not a 200 response');
        }
        if (response.headers['content-type'] !== 'application/json') {
            throw new Error('Response is not JSON');
        }
        return response;
    });
};

