import React, { useState } from "react";
import "../styles/singleselectdropdown.css";
import { useHistory } from 'react-router-dom';

const categoryQuestions = {
    "Structural Systems": {
        "Anchors": {
            "Concrete Anchors": [
                { type: 'text', label: 'What is the base material you\'re anchoring to (e.g., cracked concrete, uncracked concrete, brick)?', name: 'base_material_type' },
                { type: 'text', label: 'What is the thickness of the material you\'re anchoring?', name: 'material_thickness' },
                { type: 'text', label: 'Will the anchors be used indoors or outdoors?', name: 'usage_location' },
                { type: 'text', label: 'Do you anticipate dynamic loads or static loads on the anchors?', name: 'load_type' },
                { type: 'text', label: 'Are there any corrosion concerns (e.g., saltwater environments)?', name: 'corrosion_concerns' },
                { type: 'text', label: 'Is temporary or permanent anchoring required?', name: 'anchoring_duration' },
                { type: 'text', label: 'What is the load capacity you require for each anchor?', name: 'load_capacity' },
                { type: 'text', label: 'Is a flush or countersunk finish preferred?', name: 'finish_preference' }
            ],
            "Adhesive Anchors (Epoxy, Hybrid, and Polyester-based)": [
                { type: 'text', label: 'What is the primary base material you will be anchoring to?', name: 'primary_base_material' },
                { type: 'text', label: 'Do you need a fast or standard curing adhesive?', name: 'curing_type' },
                { type: 'text', label: 'Are there any temperature considerations for installation?', name: 'temperature_considerations' },
                { type: 'text', label: 'Will the anchors be exposed to any chemicals?', name: 'chemical_exposure' },
                { type: 'text', label: 'Are you anchoring threaded rod, rebar, or both?', name: 'anchoring_material_type' },
                { type: 'text', label: 'Do you require approvals or certifications (e.g., seismic, fire)?', name: 'approvals_required' },
                { type: 'text', label: 'What dispensing equipment do you currently have?', name: 'dispensing_equipment' },
                { type: 'text', label: 'How many adhesive anchors do you anticipate using for your project?', name: 'number_of_adhesive_anchors' },
                { type: 'text', label: 'What\'s the diameter of the holes you\'ll be anchoring into?', name: 'hole_diameter' },
                { type: 'text', label: 'Is moisture present in the base material?', name: 'moisture_presence' },
            ],
            "Mechanical Anchors (Wedge, Expansion, and Drop-in anchors)": [
                { type: 'text', label: 'What\'s the thickness of the material you are anchoring to?', name: 'material_thickness_mech' },
                { type: 'text', label: 'How close is the edge distance for the anchor placement?', name: 'edge_distance' },
                { type: 'text', label: 'Are the anchors intended for overhead applications?', name: 'overhead_applications' },
                { type: 'text', label: 'What type of fixture are you anchoring (e.g., steel plate, machinery)?', name: 'type_of_fixture' },
                { type: 'text', label: 'Is vibration a concern in the anchored fixture\'s environment?', name: 'vibration_concern' },
                { type: 'text', label: 'How often will the anchor need to be removed and replaced?', name: 'removal_frequency' },
                { type: 'text', label: 'Will the anchor be exposed to dynamic loads?', name: 'dynamic_loads' },
                { type: 'text', label: 'Do you need a tamper-proof anchor?', name: 'tamper_proof' },
                { type: 'text', label: 'Is a high load capacity essential for your application?', name: 'high_load_capacity' },
                { type: 'text', label: 'What\'s the preferred installation method (e.g., torque-controlled, displacement-controlled)?', name: 'installation_method' },
            ],
            "Screw Anchors": [
                { type: 'text', label: 'Are you anchoring into concrete, brick, or masonry?', name: 'anchoring_material_screw' },
                { type: 'text', label: 'Is a removable anchor needed for your application?', name: 'removable_anchor_screw' },
                { type: 'text', label: 'How deep is the base material you\'re anchoring into?', name: 'base_material_depth_screw' },
                { type: 'text', label: 'Are the screw anchors for a light, medium, or heavy-duty application?', name: 'duty_application_screw' },
                { type: 'text', label: 'Will they be used indoors, outdoors, or in corrosive environments?', name: 'usage_environment_screw' },
                { type: 'text', label: 'What type of head do you prefer for the screw anchor (e.g., hex head, flat head)?', name: 'head_type_screw' },
                { type: 'text', label: 'Do you require pre-drilled holes?', name: 'pre_drilled_screw' },
                { type: 'text', label: 'Is there a specific diameter and length you\'re looking for?', name: 'diameter_length_screw' },
                { type: 'text', label: 'What type of driver bit do you intend to use?', name: 'driver_bit_screw' },
                { type: 'text', label: 'How many screw anchors are needed for the project?', name: 'number_screw' }
            ],
            "Undercut Anchors": [
                { type: 'text', label: 'Are you anchoring into high-strength or standard concrete?', name: 'concrete_type_undercut' },
                { type: 'text', label: 'Will the anchors be subjected to tensile or shear loads?', name: 'load_type_undercut' },
                { type: 'text', label: 'Is this for a facade or panel attachment?', name: 'facade_panel_undercut' },
                { type: 'text', label: 'What\'s the thickness of the fixture being anchored?', name: 'fixture_thickness_undercut' },
                { type: 'text', label: 'Do you have the necessary tools for the undercut drilling process?', name: 'tools_undercut' },
                { type: 'text', label: 'How close will the anchors be placed next to each other?', name: 'anchor_distance_undercut' },
                { type: 'text', label: 'Are you adhering to specific certifications or standards?', name: 'certifications_undercut' },
                { type: 'text', label: 'How many undercut anchors do you require?', name: 'number_undercut' },
                { type: 'text', label: 'Are you familiar with the installation process for undercut anchors?', name: 'installation_familiarity_undercut' },
                { type: 'text', label: 'What\'s the load capacity requirement for your application?', name: 'load_capacity_undercut' }
            ],
            "Hollow-wall Anchors": [
                { type: 'text', label: 'What type of hollow-wall material are you anchoring to (e.g., drywall, plaster, tile)?', name: 'hollow_wall_material' },
                { type: 'text', label: 'How thick is the wall material?', name: 'wall_thickness_hollow' },
                { type: 'text', label: 'What is the weight of the object you\'re hanging?', name: 'object_weight_hollow' },
                { type: 'text', label: 'Are you looking for a tamper-resistant anchor?', name: 'tamper_resistant_hollow' },
                { type: 'text', label: 'Do you prefer a drill or a no-drill installation?', name: 'drill_preference_hollow' },
                { type: 'text', label: 'Are the anchors for a residential, commercial, or industrial application?', name: 'application_type_hollow' },
                { type: 'text', label: 'Will the anchors be visible once installed?', name: 'visibility_hollow' },
                { type: 'text', label: 'Do you require a hook-style or traditional anchor?', name: 'anchor_style_hollow' },
                { type: 'text', label: 'How many hollow-wall anchors are needed?', name: 'number_hollow' },
                { type: 'text', label: 'Are the anchors for permanent or temporary use?', name: 'usage_duration_hollow' }
            ]
            // ... Further anchors categories go here, formatted similarly ...
        },
        "Connectors": {
            "Shear Connectors": [
                { type: 'text', label: 'What\'s the primary base material you\'re connecting (e.g., steel, concrete)?', name: 'base_material_connector' },
                { type: 'text', label: 'What is the anticipated shear load on the connection?', name: 'shear_load' },
                { type: 'text', label: 'Are the connectors being used in composite beam design?', name: 'composite_beam_design' },
                { type: 'text', label: 'Will they be welded on-site or off-site?', name: 'welding_location' },
                { type: 'text', label: 'What\'s the thickness of the material being connected?', name: 'connected_material_thickness' },
                { type: 'text', label: 'How many shear connectors are required for your project?', name: 'number_of_shear_connectors' },
                { type: 'text', label: 'Are there any temperature fluctuations or considerations for the connection site?', name: 'temperature_considerations_connector' },
                { type: 'text', label: 'Do you require pre-drilled holes or a specific installation technique?', name: 'installation_requirements_connector' },
                { type: 'text', label: 'Are there specific certifications or standards you\'re adhering to (e.g., seismic)?', name: 'certifications_standards_connector' },
                { type: 'text', label: 'Is there a specific length or diameter of shear connector you\'re looking for?', name: 'length_diameter_requirements' }
            ],
            "Moment Connectors": [
                { type: 'text', label: 'What materials are being connected (e.g., timber to timber, steel to concrete)?', name: 'connected_materials_moment' },
                { type: 'text', label: 'Are these connectors being used for frame or column connections?', name: 'connection_type_moment' },
                { type: 'text', label: 'What is the anticipated moment load on the connection?', name: 'moment_load' },
                { type: 'text', label: 'Are you looking for bolted, welded, or adhesive-based connections?', name: 'connection_method_moment' },
                { type: 'text', label: 'What\'s the size and thickness of the members being connected?', name: 'member_size_thickness_moment' },
                { type: 'text', label: 'Do you have specific considerations for rotational stiffness?', name: 'rotational_stiffness' },
                { type: 'text', label: 'How many moment connectors are anticipated for your project?', name: 'number_of_moment_connectors' },
                { type: 'text', label: 'Are there any environmental concerns like corrosion or moisture?', name: 'environmental_concerns_moment' },
                { type: 'text', label: 'What type of finish are you looking for (e.g., galvanized, stainless steel)?', name: 'finish_preference_moment' },
                { type: 'text', label: 'Are there specific building codes or design standards your project must meet?', name: 'building_codes_moment' }
            ],
            "Eccentric Connectors": [
                { type: 'text', label: 'What materials are you connecting (e.g., steel, concrete, timber)?', name: 'connected_materials_eccentric' },
                { type: 'text', label: 'Are you managing offset loads or specific alignment challenges?', name: 'load_alignment_challenges' },
                { type: 'text', label: 'How many eccentric connectors are anticipated for your project?', name: 'number_of_eccentric_connectors' },
                { type: 'text', label: 'Are these connectors for temporary or permanent connections?', name: 'connection_duration_eccentric' },
                { type: 'text', label: 'Is there a particular size or load capacity you\'re looking for?', name: 'size_load_capacity_eccentric' },
                { type: 'text', label: 'What kind of installation method do you prefer (e.g., bolted, welded)?', name: 'installation_method_eccentric' },
                { type: 'text', label: 'Are there any considerations for adjustability post-installation?', name: 'adjustability_post_installation' },
                { type: 'text', label: 'Will the connectors be exposed to dynamic loads or vibrations?', name: 'dynamic_loads_vibrations_eccentric' },
                { type: 'text', label: 'Do you have specific corrosion or material finishing requirements?', name: 'corrosion_finish_requirements_eccentric' },
                { type: 'text', label: 'Are there any specific building codes or regulations that need to be adhered to for your application?', name: 'building_codes_eccentric' }
            ],
            // ... Additional connector categories and questions ...
        },
        "Fasteners": {
            "Direct Fastening (Powder-actuated, Gas-actuated)": [
                { type: 'text', label: 'What base materials are you fastening into (e.g., steel, concrete, masonry)?', name: 'fastening_base_materials' },
                { type: 'text', label: 'Are you looking for single-shot or magazine-fed tools?', name: 'tool_type' },
                { type: 'text', label: 'What\'s the thickness of the material you\'re attaching?', name: 'attached_material_thickness' },
                { type: 'text', label: 'What is the expected volume of fasteners you\'ll be installing daily?', name: 'daily_installation_volume' },
                { type: 'text', label: 'Are there specific environmental concerns, like limited ventilation or noise constraints?', name: 'environmental_concerns_fasteners' },
                { type: 'text', label: 'What\'s the desired fastener length and diameter?', name: 'fastener_length_diameter' },
                { type: 'text', label: 'Are there specific certifications or standards you need to adhere to (e.g., seismic)?', name: 'certifications_standards_fasteners' },
                { type: 'text', label: 'Do you need corrosion-resistant fasteners?', name: 'corrosion_resistant_fasteners' },
                { type: 'text', label: 'Are you working in overhead, wall, or floor applications?', name: 'application_type_fasteners' },
                { type: 'text', label: 'Are you looking for specific pin or fastener type, such as threaded studs, flat head, etc.?', name: 'fastener_type_preference' }
            ],
            "Screw Fastening": [
                { type: 'text', label: 'What materials are you joining together (e.g., wood, metal, gypsum)?', name: 'joining_materials_screw' },
                { type: 'text', label: 'What\'s the desired screw length and diameter?', name: 'screw_length_diameter' },
                { type: 'text', label: 'Are you looking for corrosion-resistant screws or a specific finish (e.g., stainless steel, zinc-coated)?', name: 'corrosion_resistant_screws' },
                { type: 'text', label: 'What\'s the head type preference (e.g., countersunk, pan, hex)?', name: 'head_type_preference_screw' },
                { type: 'text', label: 'How many screws are anticipated for your project?', name: 'number_of_screws' },
                { type: 'text', label: 'Are there specific standards or building codes your project must meet?', name: 'standards_building_codes_screw' },
                { type: 'text', label: 'What\'s the expected volume of screws you\'ll be installing daily?', name: 'daily_installation_volume_screw' },
                { type: 'text', label: 'Are you using a manual or powered installation method?', name: 'installation_method_screw' },
                { type: 'text', label: 'Are there any pre-drilling requirements or considerations?', name: 'pre_drilling_requirements_screw' },
                { type: 'text', label: 'Are you considering screws with any special features, like self-tapping or self-drilling?', name: 'special_features_screw' }
            ],
            "Nail Fastening": [
                { type: 'text', label: 'What materials are you nailing into (e.g., wood, masonry)?', name: 'nailing_materials' },
                { type: 'text', label: 'What\'s the desired nail length and diameter?', name: 'nail_length_diameter' },
                { type: 'text', label: 'Are you using a pneumatic, gas, or manual nailer?', name: 'nailer_type' },
                { type: 'text', label: 'What nail finish do you require (e.g., galvanized, stainless steel)?', name: 'nail_finish_preference' },
                { type: 'text', label: 'Are you looking for specific nail types, such as framing, finish, or brad nails?', name: 'nail_type_preference' },
                { type: 'text', label: 'How many nails are anticipated for your project?', name: 'number_of_nails' },
                { type: 'text', label: 'Do you need nails with specific features, like ring shank or helical?', name: 'special_features_nail' },
                { type: 'text', label: 'What is the expected volume of nails you\'ll be installing daily?', name: 'daily_installation_volume_nail' },
                { type: 'text', label: 'Are there specific building codes or regulations for your application?', name: 'building_codes_nail' },
                { type: 'text', label: 'Are you considering coil nails, stick nails, or individual nails?', name: 'nail_format' }
            ],
            "Bolt Fastening": [
                { type: 'text', label: 'What\'s the thickness of the materials being joined?', name: 'material_thickness_bolt' },
                { type: 'text', label: 'Are you bolting through an object or using them for clamping purposes?', name: 'bolt_purpose' },
                { type: 'text', label: 'What material are you looking for in bolts (e.g., steel, stainless steel, galvanized)?', name: 'bolt_material_preference' },
                { type: 'text', label: 'What bolt diameter and length are you considering?', name: 'bolt_diameter_length' },
                { type: 'text', label: 'How many bolts are anticipated for your project?', name: 'number_of_bolts' },
                { type: 'text', label: 'Are there specific standards or building codes your project must meet (e.g., structural, seismic)?', name: 'standards_building_codes_bolt' },
                { type: 'text', label: 'What type of head do you prefer for the bolts (e.g., hex, socket)?', name: 'bolt_head_preference' },
                { type: 'text', label: 'Do you need corresponding nuts and washers?', name: 'nuts_washers_required' },
                { type: 'text', label: 'What environmental conditions (e.g., corrosion, temperature fluctuations) will the bolts be exposed to?', name: 'environmental_conditions_bolt' },
                { type: 'text', label: 'Are you considering any specific bolt grade or tensile strength?', name: 'bolt_grade_strength' }
            ],
            // ... Additional fastener categories and questions ...
        },
        "Concrete and Aggregates": {
            "Ready-Mix Concrete": [
                { type: 'text', label: 'What type of project are you using the concrete for (e.g., residential, commercial, infrastructure)?', name: 'concrete_project_type' },
                { type: 'text', label: 'Do you require any specific strength or performance characteristics?', name: 'concrete_strength_characteristics' },
                { type: 'text', label: 'Are you looking for any admixtures or special additives?', name: 'admixtures_additives' },
                { type: 'text', label: 'What volume of concrete is required?', name: 'concrete_volume_required' },
                { type: 'text', label: 'Do you have accessibility issues at the project site?', name: 'accessibility_issues' },
                { type: 'text', label: 'Are there any environmental considerations, like sustainability or low CO2 concrete?', name: 'environmental_considerations_concrete' },
                { type: 'text', label: 'How long is your desired curing time?', name: 'curing_time' },
                { type: 'text', label: 'Do you need a pump or any other special delivery method?', name: 'delivery_method' },
                { type: 'text', label: 'Are there any finish or aesthetic requirements?', name: 'finish_aesthetic_requirements' },
                { type: 'text', label: 'What is your project\'s location and delivery window?', name: 'delivery_location_window' }
            ],
            "Aggregates (Sand, Gravel, Crushed Stone)": [
                { type: 'text', label: 'What type of aggregate are you looking for?', name: 'aggregate_type' },
                { type: 'text', label: 'What\'s the intended use of the aggregate (e.g., concrete mix, road construction, drainage)?', name: 'aggregate_intended_use' },
                { type: 'text', label: 'What size and grading do you require?', name: 'aggregate_size_grading' },
                { type: 'text', label: 'How many tons or cubic meters are needed for your project?', name: 'aggregate_quantity_required' },
                { type: 'text', label: 'Are there specific quality standards or certifications you\'re aiming for?', name: 'aggregate_quality_standards' },
                { type: 'text', label: 'Do you require a consistent source over a long period?', name: 'consistent_source_required' },
                { type: 'text', label: 'How will the aggregates be transported and delivered?', name: 'delivery_method_aggregate' },
                { type: 'text', label: 'Are there environmental or sustainability considerations?', name: 'environmental_considerations_aggregate' },
                { type: 'text', label: 'Do you need any specific technical support or testing?', name: 'technical_support_testing' },
                { type: 'text', label: 'Are there local or regional regulations or building codes to consider?', name: 'regulations_building_codes' }
            ],
            "Cement": [
                { type: 'text', label: 'Are you looking for a particular type of cement (e.g., Portland, blended)?', name: 'cement_type_preference' },
                { type: 'text', label: 'What\'s the desired setting time and strength?', name: 'setting_time_strength' },
                { type: 'text', label: 'Do you require cement with specific resistances (e.g., sulfate, chloride)?', name: 'resistance_requirements' },
                { type: 'text', label: 'How many bags or tons are anticipated for your project?', name: 'quantity_required_cement' },
                { type: 'text', label: 'Are there environmental considerations, like low CO2 cement?', name: 'environmental_considerations_cement' },
                { type: 'text', label: 'Do you need cement with any special additives?', name: 'additives_required' },
                { type: 'text', label: 'How will the cement be stored on-site?', name: 'storage_method_cement' },
                { type: 'text', label: 'Are there transportation and delivery considerations?', name: 'transportation_delivery_cement' },
                { type: 'text', label: 'What are the packaging preferences (e.g., bulk, bags)?', name: 'packaging_preference_cement' },
                { type: 'text', label: 'Are there specific building codes or standards your cement needs to adhere to?', name: 'building_codes_standards_cement' }
            ],
            "Precast Concrete Elements": [
                { type: 'text', label: 'Are you considering structural or architectural precast?', name: 'precast_type' },
                { type: 'text', label: 'What specific elements are you interested in (e.g., beams, walls, facades)?', name: 'precast_elements_interest' },
                { type: 'text', label: 'Do you have specific design or aesthetic preferences?', name: 'design_aesthetic_preferences' },
                { type: 'text', label: 'What\'s the volume or number of precast elements needed?', name: 'volume_number_precast' },
                { type: 'text', label: 'Are there specific installation or logistics concerns?', name: 'installation_logistics_concerns' },
                { type: 'text', label: 'Do you require any post-treatment or finishing on the precast elements?', name: 'post_treatment_finishing' },
                { type: 'text', label: 'Are the elements for indoor or outdoor use?', name: 'indoor_outdoor_use' },
                { type: 'text', label: 'Do you need accompanying accessories or fasteners for the elements?', name: 'accessories_fasteners_required' },
                { type: 'text', label: 'Are there specific building codes or regulations to consider?', name: 'building_codes_regulations_precast' },
                { type: 'text', label: 'Is there a timeline for manufacturing and delivery?', name: 'timeline_manufacturing_delivery' }
            ],
        },
        // ... Further primary categories (like Connectors, Fasteners) under "Structural Systems" go here, formatted similarly ...
    },
    // ... Further top-level categories (like "Concrete and Aggregates") go here, formatted similarly ...
    "Mechanical, Electrical & Plumbing (MEP) Systems": {
        "Mechanical Solutions": {
            "HVAC Fixation Systems": [
                { type: 'text', label: 'What types of HVAC equipment will you be installing (e.g., ducts, units, pipes)?', name: 'hvac_equipment_type' },
                { type: 'text', label: 'What\'s the total weight or size of the HVAC components needing fixation?', name: 'hvac_component_weight_size' },
                { type: 'text', label: 'Are there specific environmental conditions the fixations need to withstand (e.g., humidity, temperature)?', name: 'environmental_conditions' },
                { type: 'text', label: 'What types of substrates will the HVAC components be fixed to (e.g., concrete, steel, wood)?', name: 'substrates_type' },
                { type: 'text', label: 'Are there specific requirements for vibration or noise dampening?', name: 'vibration_noise_requirements' },
                { type: 'text', label: 'Do you require adjustable or fixed supports?', name: 'support_type' },
                { type: 'text', label: 'What\'s the expected lifetime or durability requirement for the fixation systems?', name: 'durability_requirements' },
                { type: 'text', label: 'Are there specific safety or load-bearing standards to adhere to?', name: 'safety_standards' },
                { type: 'text', label: 'Is space a constraint for the fixation systems?', name: 'space_constraints' },
                { type: 'text', label: 'Are there any other utility integrations or obstructions to consider during fixation?', name: 'utility_obstructions' }
            ],
            "Plumbing Systems & Accessories": [
                { type: 'text', label: 'What types of pipes and fixtures are being used (e.g., copper, PVC, PEX)?', name: 'pipe_fixture_types' },
                { type: 'text', label: 'Are there specific requirements for temperature resistance or insulation?', name: 'temperature_resistance_insulation' },
                { type: 'text', label: 'Will the plumbing systems be exposed or concealed?', name: 'exposed_concealed_plumbing' },
                { type: 'text', label: 'What\'s the water pressure requirement for the system?', name: 'water_pressure_requirement' },
                { type: 'text', label: 'Are there specific safety or backup systems needed (e.g., pressure relief, anti-siphon)?', name: 'safety_backup_systems' },
                { type: 'text', label: 'Are there any water quality or treatment considerations?', name: 'water_quality_treatment' },
                { type: 'text', label: 'Do you need accessories like clamps, hangers, or couplings?', name: 'accessories_required' },
                { type: 'text', label: 'Is there a requirement for noise or vibration dampening for the plumbing system?', name: 'noise_vibration_dampening' },
                { type: 'text', label: 'What\'s the total length or area of the plumbing installation?', name: 'total_length_area' },
                { type: 'text', label: 'Are there any special considerations for water drainage or waste management?', name: 'water_drainage_waste_management' }
            ]
        },
        "Electrical Solutions": {
            "Electrical Installation Systems": [
                { type: 'text', label: 'What\'s the scale of the electrical system (e.g., residential, commercial, industrial)?', name: 'electrical_system_scale' },
                { type: 'text', label: 'Are there specific requirements for grounding or earthing?', name: 'grounding_earthing_requirements' },
                { type: 'text', label: 'Do you need specific components like circuit breakers, switchgear, or transformers?', name: 'specific_components_required' },
                { type: 'text', label: 'What types of protection devices are required (e.g., fuses, surge protectors)?', name: 'protection_devices_required' },
                { type: 'text', label: 'Are there specific load or capacity considerations for the installation?', name: 'load_capacity_considerations' },
                { type: 'text', label: 'What types of cables or wires are being used, and what are their specifications?', name: 'cables_wires_specifications' },
                { type: 'text', label: 'Are there specific installation methods preferred (e.g., overhead, underground)?', name: 'installation_methods_preferred' },
                { type: 'text', label: 'What\'s the total area or length of the electrical installation?', name: 'total_area_length' },
                { type: 'text', label: 'Are there requirements for expandability or future modifications?', name: 'expandability_modifications_requirements' },
                { type: 'text', label: 'Are there specific safety or regulatory standards to meet?', name: 'safety_regulatory_standards' }
            ],
            "Cable Management & Routing": [
                { type: 'text', label: 'How many cables or wires need management or routing?', name: 'number_of_cables_wires' },
                { type: 'text', label: 'Are there specific types or sizes of conduits or trays preferred?', name: 'preferred_conduits_trays' },
                { type: 'text', label: 'Do you require flexibility or modularity in the routing system?', name: 'flexibility_modularity_required' },
                { type: 'text', label: 'What are the environmental considerations (e.g., indoor, outdoor, wet areas)?', name: 'environmental_considerations' },
                { type: 'text', label: 'Are aesthetics a consideration for visible cable management systems?', name: 'aesthetics_considerations' },
                { type: 'text', label: 'Are there specific load-bearing or weight considerations?', name: 'load_bearing_weight_considerations' },
                { type: 'text', label: 'Do you need accessories like junction boxes, connectors, or fasteners?', name: 'accessories_needed' },
                { type: 'text', label: 'What\'s the total length or path of the cable management system?', name: 'total_length_path' },
                { type: 'text', label: 'Are there electromagnetic interference or cross-talk considerations?', name: 'electromagnetic_interference_considerations' },
                { type: 'text', label: 'Are there specific safety or fire-resistance requirements?', name: 'safety_fire_resistance_requirements' }
            ],
            "Enclosures & Cabinets": [
                { type: 'text', label: 'What\'s the intended use of the enclosure or cabinet (e.g., switchboard, server, general storage)?', name: 'intended_use' },
                { type: 'text', label: 'What\'s the required size or capacity?', name: 'required_size_capacity' },
                { type: 'text', label: 'Are there specific requirements for weather-resistance, waterproofing, or dust-proofing?', name: 'weather_resistance_requirements' },
                { type: 'text', label: 'Do you require ventilation, cooling, or heating solutions within the enclosure?', name: 'ventilation_cooling_heating_required' },
                { type: 'text', label: 'What are the security considerations (e.g., lock systems, access controls)?', name: 'security_considerations' },
                { type: 'text', label: 'Are there specific materials preferred for the enclosures or cabinets?', name: 'preferred_materials' },
                { type: 'text', label: 'Is modularity or expandability a requirement?', name: 'modularity_expandability_required' },
                { type: 'text', label: 'What\'s the expected lifetime or durability of the enclosure or cabinet?', name: 'expected_lifetime_durability' },
                { type: 'text', label: 'Do you require specific mounting or installation solutions?', name: 'mounting_installation_requirements' },
                { type: 'text', label: 'Are there any specific safety or regulatory standards the enclosures need to meet?', name: 'safety_regulatory_standards' }
            ]
        }
    },
    "Concrete": {
        "Masonry and Blocks": {
            "Concrete Blocks": [
                { "type": "text", "label": "What is the intended use for the concrete blocks (e.g., structural walls, retaining walls, partitions)?", "name": "intended_use_for_concrete_blocks" },
                { "type": "text", "label": "What size and type of block are you considering (e.g., hollow, solid)?", "name": "size_and_type_of_block" },
                { "type": "text", "label": "How many blocks are anticipated for your project?", "name": "number_of_blocks" },
                { "type": "text", "label": "Are you considering a specific block finish (e.g., smooth, split-face)?", "name": "specific_block_finish" },
                { "type": "text", "label": "Do the blocks need to meet certain fire resistance or insulation standards?", "name": "fire_resistance_or_insulation_standards" },
                { "type": "text", "label": "Are there specific compressive strength requirements?", "name": "compressive_strength_requirements" },
                { "type": "text", "label": "How will the blocks be transported and stored on-site?", "name": "transportation_and_storage_on_site" },
                { "type": "text", "label": "Are there color or aesthetic preferences for the blocks?", "name": "color_or_aesthetic_preferences" },
                { "type": "text", "label": "Do you need additional masonry accessories or reinforcements?", "name": "additional_masonry_accessories_or_reinforcements" },
                { "type": "text", "label": "Are there any specific building codes or standards that the blocks need to adhere to?", "name": "building_codes_or_standards" }
            ],
            "Bricks": [
                { "type": "text", "label": "What type of bricks are you considering (e.g., clay, concrete, firebrick)?", "name": "type_of_bricks" },
                { "type": "text", "label": "How many bricks are required for your project?", "name": "number_of_bricks" },
                { "type": "text", "label": "Are the bricks for structural, facing, or decorative purposes?", "name": "purpose_of_bricks" },
                { "type": "text", "label": "What size and shape of bricks are you considering?", "name": "size_and_shape_of_bricks" },
                { "type": "text", "label": "Do you have specific color or texture preferences?", "name": "color_or_texture_preferences" },
                { "type": "text", "label": "Are there specific compressive strength or durability requirements?", "name": "compressive_strength_or_durability" },
                { "type": "text", "label": "How will the bricks be transported and stored on-site?", "name": "brick_transportation_and_storage" },
                { "type": "text", "label": "Are you looking for bricks with specific thermal or acoustic properties?", "name": "thermal_or_acoustic_properties" },
                { "type": "text", "label": "Do you need mortar or other masonry accessories?", "name": "mortar_or_masonry_accessories" },
                { "type": "text", "label": "Are there specific building codes or standards that the bricks need to adhere to?", "name": "brick_building_codes_or_standards" }
            ],
            "Pavers": [
                { "type": "text", "label": "What material pavers are you considering (e.g., concrete, clay, stone)?", "name": "material_of_pavers" },
                { "type": "text", "label": "How many square meters or square feet of pavers do you need?", "name": "area_of_pavers_required" },
                { "type": "text", "label": "What's the intended use for the pavers (e.g., driveways, walkways, patios)?", "name": "intended_use_for_pavers" },
                { "type": "text", "label": "Do you have specific color, shape, or texture preferences for the pavers?", "name": "paver_color_shape_texture_preferences" },
                { "type": "text", "label": "Are there specific load or durability requirements?", "name": "load_or_durability_requirements_for_pavers" },
                { "type": "text", "label": "Are you considering permeable or non-permeable pavers?", "name": "permeable_or_non_permeable_pavers" },
                { "type": "text", "label": "What type of base or bedding material will you be using?", "name": "base_or_bedding_material" },
                { "type": "text", "label": "How will the pavers be transported and stored on-site?", "name": "paver_transportation_and_storage" },
                { "type": "text", "label": "Do you need any specific edge restraints or installation accessories?", "name": "edge_restraints_or_installation_accessories" },
                { "type": "text", "label": "Are there any specific building codes or landscaping regulations to consider?", "name": "building_codes_or_landscaping_regulations_for_pavers" }
            ]
        },
        "Asphalt and Paving Materials": {
            "Hot-Mix Asphalt": [
                { "type": "text", "label": "What's the intended use of the hot-mix asphalt (e.g., highways, parking lots, driveways)?", "name": "intended_use_of_hot_mix_asphalt" },
                { "type": "text", "label": "How many tons of asphalt are needed for your project?", "name": "tons_of_asphalt_needed" },
                { "type": "text", "label": "Are you considering a specific mix design or aggregate size?", "name": "specific_mix_or_aggregate_size" },
                { "type": "text", "label": "What are your requirements for performance and durability?", "name": "performance_and_durability_requirements" },
                { "type": "text", "label": "Are there temperature or seasonal considerations for laying the asphalt?", "name": "temperature_or_seasonal_considerations" },
                { "type": "text", "label": "Do you need additives or modifiers (e.g., polymers, fibers)?", "name": "need_for_additives_or_modifiers" },
                { "type": "text", "label": "How will the asphalt be transported and applied on-site?", "name": "asphalt_transportation_and_application" },
                { "type": "text", "label": "Are there environmental or sustainability considerations?", "name": "environmental_or_sustainability_considerations" },
                { "type": "text", "label": "Do you need any specific testing or quality assurance measures?", "name": "testing_or_quality_assurance_measures" },
                { "type": "text", "label": "Are there specific road or transportation codes to adhere to?", "name": "road_or_transportation_codes" }
            ],
            "Cold-Mix Asphalt": [
                { "type": "text", "label": "What's the primary purpose of the cold-mix asphalt (e.g., patching, temporary surfaces)?", "name": "primary_purpose_of_cold_mix" },
                { "type": "text", "label": "How many tons are anticipated for your project?", "name": "tons_of_cold_mix_needed" },
                { "type": "text", "label": "Do you have specific performance or curing time requirements?", "name": "performance_or_curing_time_requirements" },
                { "type": "text", "label": "Are there temperature or weather considerations for using the mix?", "name": "temperature_or_weather_considerations" },
                { "type": "text", "label": "How will the cold-mix be stored and applied on-site?", "name": "cold_mix_storage_and_application" },
                { "type": "text", "label": "Are there specific road or transportation codes to consider?", "name": "cold_mix_road_or_transportation_codes" },
                { "type": "text", "label": "Do you need any specific equipment or tools for the application?", "name": "specific_equipment_or_tools" },
                { "type": "text", "label": "Are there environmental or sustainability considerations?", "name": "cold_mix_environmental_considerations" },
                { "type": "text", "label": "Do you require specific binders or additives in the mix?", "name": "binders_or_additives_in_cold_mix" },
                { "type": "text", "label": "What's the intended lifespan or durability for the applied material?", "name": "intended_lifespan_or_durability" }
            ],
            "Bitumen and Binders": [
                { "type": "text", "label": "What's the intended use for the bitumen (e.g., asphalt production, waterproofing)?", "name": "intended_use_for_bitumen" },
                { "type": "text", "label": "How many tons or barrels are needed for your project?", "name": "tons_or_barrels_needed" },
                { "type": "text", "label": "Are you considering a specific grade or type of bitumen?", "name": "specific_grade_or_type_of_bitumen" },
                { "type": "text", "label": "Do you have temperature or performance specifications?", "name": "temperature_or_performance_specifications" },
                { "type": "text", "label": "How will the bitumen be stored and handled on-site?", "name": "bitumen_storage_and_handling" },
                { "type": "text", "label": "Are there environmental or health and safety considerations?", "name": "environmental_or_health_and_safety_considerations" },
                { "type": "text", "label": "Do you require modified bitumen or specific additives?", "name": "modified_bitumen_or_specific_additives" },
                { "type": "text", "label": "How will the bitumen be transported to the site?", "name": "bitumen_transportation" },
                { "type": "text", "label": "Are there specific building codes or transportation standards to consider?", "name": "building_codes_or_transportation_standards" },
                { "type": "text", "label": "Do you need technical support or testing for the bitumen application?", "name": "technical_support_or_testing" }
            ]
        },
        "Masonry and Plaster": {
            "Masonry Blocks (Bricks, Blocks, and Stone)": [
                { "type": "text", "label": "What is the primary use for these masonry units (e.g., facade, load-bearing)?", "name": "primary_use_for_masonry_units" },
                { "type": "text", "label": "Are you looking for a specific finish (e.g., smooth, split-face, textured)?", "name": "specific_finish_for_masonry_units" },
                { "type": "text", "label": "Do you have specific fire resistance or acoustic requirements?", "name": "fire_resistance_or_acoustic_requirements" },
                { "type": "text", "label": "How many units do you anticipate needing for your project?", "name": "number_of_units_needed" },
                { "type": "text", "label": "Are there color preferences or aesthetic considerations?", "name": "color_preferences_or_aesthetic_considerations" },
                { "type": "text", "label": "What are the structural requirements for these units?", "name": "structural_requirements_for_units" },
                { "type": "text", "label": "Are you looking for standard sizes or custom dimensions?", "name": "standard_sizes_or_custom_dimensions" },
                { "type": "text", "label": "Do these blocks need to be compatible with insulation or reinforcement systems?", "name": "compatibility_with_insulation_or_reinforcement" },
                { "type": "text", "label": "Is there a sustainability or recycled content requirement?", "name": "sustainability_or_recycled_content_requirement" },
                { "type": "text", "label": "Are you adhering to any specific building codes or standards?", "name": "adherence_to_building_codes_or_standards" }
            ],
            "Mortar and Grout": [
                { "type": "text", "label": "What type of masonry material are you joining or filling?", "name": "type_of_masonry_material" },
                { "type": "text", "label": "Do you require a specific curing time or strength?", "name": "curing_time_or_strength_requirement" },
                { "type": "text", "label": "Is there a color preference for aesthetic compatibility?", "name": "color_preference_for_aesthetic_compatibility" },
                { "type": "text", "label": "Will it be exposed to any chemicals or harsh conditions?", "name": "exposure_to_chemicals_or_conditions" },
                { "type": "text", "label": "How many bags or tons are you anticipating for your project?", "name": "quantity_of_bags_or_tons_needed" },
                { "type": "text", "label": "Are you seeking any specific features like anti-shrink or crack resistance?", "name": "specific_features_like_anti_shrink_or_crack_resistance" },
                { "type": "text", "label": "Is there a concern about the temperature or weather during application?", "name": "temperature_or_weather_concern_during_application" },
                { "type": "text", "label": "Do you require admixtures or performance enhancers?", "name": "requirement_for_admixtures_or_enhancers" },
                { "type": "text", "label": "How will the mortar/grout be mixed and applied?", "name": "method_of_mixing_and_application" },
                { "type": "text", "label": "Are there specific building codes or standards your product needs to adhere to?", "name": "building_codes_or_standards_for_product" }
            ]
        },
        "Admixtures and Additives": {
            "Water Reducers": [
                { "type": "text", "label": "What's the primary purpose of the water reducer in your mix?", "name": "primary_purpose_of_water_reducer" },
                { "type": "text", "label": "Are you looking for a particular dosage or concentration?", "name": "dosage_or_concentration_preference" },
                { "type": "text", "label": "What type of concrete are you producing (e.g., precast, ready-mix)?", "name": "type_of_concrete_production" },
                { "type": "text", "label": "Do you require any certifications (e.g., ASTM)?", "name": "required_certifications" },
                { "type": "text", "label": "Are you looking for a specific workability or setting time?", "name": "specific_workability_or_setting_time" },
                { "type": "text", "label": "How will the admixture be stored and dosed?", "name": "storage_and_dosing_of_admixture" },
                { "type": "text", "label": "Are there any compatibility issues with other ingredients in your mix?", "name": "compatibility_issues" },
                { "type": "text", "label": "What's the expected volume of concrete to be produced?", "name": "expected_volume_of_concrete" },
                { "type": "text", "label": "Are there environmental or sustainability considerations?", "name": "environmental_or_sustainability_considerations" },
                { "type": "text", "label": "Is there a need for technical support or on-site assistance?", "name": "need_for_technical_support_or_assistance" }
            ],
            "Accelerators": [
                { "type": "text", "label": "Are you aiming for a specific setting time or early strength?", "name": "specific_setting_time_or_strength" },
                { "type": "text", "label": "What kind of climate or temperature will the concrete be exposed to during curing?", "name": "climate_or_temperature_during_curing" },
                { "type": "text", "label": "Are you concerned about any potential discoloration or surface effects?", "name": "concern_about_discoloration_or_surface_effects" },
                { "type": "text", "label": "How will the accelerator be incorporated into the mix?", "name": "incorporation_of_accelerator" },
                { "type": "text", "label": "Do you have any requirements related to corrosion or material compatibility?", "name": "corrosion_or_material_compatibility_requirements" },
                { "type": "text", "label": "Are there specific certifications or standards you're adhering to?", "name": "specific_certifications_or_standards" },
                { "type": "text", "label": "How many cubic meters of concrete will require the accelerator?", "name": "cubic_meters_needing_accelerator" },
                { "type": "text", "label": "Is there a need for compatibility testing with your mix?", "name": "need_for_compatibility_testing" },
                { "type": "text", "label": "Do you have any restrictions on the type of accelerators (e.g., calcium chloride-free)?", "name": "restrictions_on_accelerator_type" },
                { "type": "text", "label": "Are there environmental or health and safety considerations?", "name": "environmental_or_health_and_safety_considerations" }
            ]
        },
        "Flooring Solutions": {
            "Self-Leveling Underlayments": [
                { "type": "text", "label": "What type of substrate are you applying the underlayment to?", "name": "substrate_for_underlayment" },
                { "type": "text", "label": "Are there requirements for thickness or leveling height?", "name": "thickness_or_leveling_height_requirements" },
                { "type": "text", "label": "How soon do you intend to apply the final floor covering?", "name": "time_until_final_floor_covering_application" },
                { "type": "text", "label": "Are there specific performance requirements, such as load-bearing or flexibility?", "name": "specific_performance_requirements" },
                { "type": "text", "label": "What's the estimated area to be covered?", "name": "estimated_area_to_be_covered" },
                { "type": "text", "label": "Are there any moisture or vapor barrier considerations?", "name": "moisture_or_vapor_barrier_considerations" },
                { "type": "text", "label": "Do you need a specific finish (e.g., smooth, textured)?", "name": "specific_finish_requirement" },
                { "type": "text", "label": "What kind of traffic will the floor be subjected to?", "name": "kind_of_traffic_for_floor" },
                { "type": "text", "label": "Are there temperature or humidity considerations during application?", "name": "temperature_or_humidity_considerations" },
                { "type": "text", "label": "Do you require specific certifications, like VOC compliance or LEED credits?", "name": "required_certifications_for_flooring" }
            ],
            "Decorative Concrete": [
                { "type": "text", "label": "What kind of aesthetic or finish are you aiming for (e.g., stamped, polished)?", "name": "aesthetic_or_finish_preference" },
                { "type": "text", "label": "Will it be an indoor or outdoor application?", "name": "indoor_or_outdoor_application" },
                { "type": "text", "label": "Are you looking for specific colors or patterns?", "name": "specific_colors_or_patterns" },
                { "type": "text", "label": "How much area will you be covering with decorative concrete?", "name": "area_covered_with_decorative_concrete" },
                { "type": "text", "label": "Are there performance requirements, like slip resistance or durability?", "name": "performance_requirements_for_decorative_concrete" },
                { "type": "text", "label": "Will there be exposure to chemicals, UV, or harsh weather?", "name": "exposure_to_chemicals_uv_or_weather" },
                { "type": "text", "label": "Are you considering additional sealers or protective coatings?", "name": "consideration_for_sealers_or_coatings" },
                { "type": "text", "label": "Is there a sustainability or eco-friendly requirement?", "name": "sustainability_or_eco_friendly_requirement" },
                { "type": "text", "label": "What's your desired maintenance regime for the finished floor?", "name": "maintenance_regime_finished_floor"}
            ]
        },
        "Roofing Solutions": {
            "Waterproofing Membranes": [
                { "type": "text", "label": "What type of substrate will the membrane be applied to (e.g., concrete, plywood)?", "name": "substrate_for_membrane_application" },
                { "type": "text", "label": "Are you looking for sheet-based or liquid-applied membranes?", "name": "sheet_based_or_liquid_applied_preference" },
                { "type": "text", "label": "What's the anticipated foot traffic on the finished roof?", "name": "anticipated_foot_traffic" },
                { "type": "text", "label": "How many square meters will be covered by the membrane?", "name": "square_meters_to_be_covered" },
                { "type": "text", "label": "Are there any specific temperature or UV resistance requirements?", "name": "temperature_or_uv_resistance_requirements" },
                { "type": "text", "label": "Do you need the membrane to be root-resistant (for green roofs)?", "name": "requirement_for_root_resistance" },
                { "type": "text", "label": "How will the membrane be adhered or fixed?", "name": "method_of_membrane_adherence_or_fixation" },
                { "type": "text", "label": "Are there requirements for insulation or energy efficiency?", "name": "insulation_or_energy_efficiency_requirements" },
                { "type": "text", "label": "Do you have a preferred finish or aesthetic for the membrane?", "name": "preferred_finish_or_aesthetic" },
                { "type": "text", "label": "Are there any specific certifications or standards, like fire rating or VOC compliance?", "name": "specific_certifications_or_standards" }
            ]
        }   
    } 
};

const services = Object.keys(categoryQuestions);

// List of U.S. States
//const USStates = [
//    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
//    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
//    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
//    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
//    'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
//    'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
//    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
//];

const data = services.map((service, index) => ({
    id: index + 1,
    title: service
}));

function SingleSelectDropdown() {
    const [selected, setSelectedLocal] = useState(0);
    const [isSelectionMade, setIsSelectionMade] = useState(false);
    const [secondDropdownData, setSecondDropdownData] = useState([]);
    const [selectedSecond, setSelectedSecond] = useState(0);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [selectedSubTitle, setSelectedSubTitle] = useState(null);
    const [siteAddress, setSiteAddress] = useState("");
    const [state, setState] = useState("");
    const [siteZipcode, setSiteZipcode] = useState("");

    const history = useHistory();

    const transformInputObject = (inputObj) => {
        let result = {};

        for (let category in inputObj) {
            if (inputObj.hasOwnProperty(category)) {
                result[category] = Object.keys(inputObj[category]);
            }
        }

        return result;
    }

    const handleChange = (event) => {
        const selectedId = parseInt(event.target.value);
        setSelectedLocal(selectedId);

        let subServiceToCategories = {};

        if (selectedId !== 0) {
            setSelectedTitle(data.find(option => option.id === selectedId)?.title);
            const title = data.find(option => option.id === selectedId)?.title;
            subServiceToCategories = transformInputObject(categoryQuestions[title]);
            setSecondDropdownData(Object.keys(subServiceToCategories));
        } else {
            setIsSelectionMade(false);
            setSecondDropdownData([]);
        }
    };

    const handleChangeSecond = (event) => {
        const selectedIndex = parseInt(event.target.value) - 1;
        setSelectedSubTitle(secondDropdownData[selectedIndex]);
        setSelectedSecond(event.target.value);
        setIsSelectionMade(true);
    };

    const handleSubmit = () => {
        localStorage.setItem('selectedServiceTitle', selectedTitle);
        localStorage.setItem('selectedSubServiceTitle', selectedSubTitle);
        localStorage.setItem('siteAddress', siteAddress);
        localStorage.setItem('state', state);
        localStorage.setItem('siteZipcode', siteZipcode);
        history.push('/select-material-category');
        /* window.location.replace('/product-type'); */
    };

    return (
        <div className="c-single-select-dropdown">
            <select
                value={selected}
                onChange={handleChange}
                className="c-single-select-dropdown__select"
            >
                <option value={0}>Select an option</option>
                {data.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.title}
                    </option>
                ))}
            </select>

            {secondDropdownData.length > 0 && (
                <select
                    value={selectedSecond}
                    onChange={handleChangeSecond}
                    className="c-single-select-dropdown__select_second"
                >
                    <option value={0}>Select an option</option>
                    {secondDropdownData.map((item, index) => (
                        <option key={index} value={index + 1}>
                            {item}
                        </option>
                    ))}
                </select>
            )}

        {isSelectionMade && (
            <div>
                <input
                    type="text"
                    placeholder="Site Zipcode"
                    value={siteZipcode}
                    onChange={(e) => setSiteZipcode(e.target.value)}
                    className="c-single-select-dropdown__input" // Added class name
                />
            </div>
        )}

            <div className="submit-button-container">
                <button
                    disabled={!isSelectionMade || !siteZipcode}
                    onClick={handleSubmit}
                    className={isSelectionMade ? 'active' : 'inactive'}
                >
                    Submit
                </button>
            </div>
        </div>
    );
}

export default SingleSelectDropdown;
