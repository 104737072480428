// Navbar.js
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import logo from "../images/logo.png";
import "../styles/navbar.css";

const Navbar = ({ logout, isAuthenticated }) => {
    const history = useHistory();

    const logout_user = () => {
        logout();
        history.push("/select-product");
    };

    const guestLinks = () => (
        <Fragment>
            <li className='nav-item'>
                <a className="nav-link" href="/login">Login</a>
            </li>
            <li className='nav-item'>
                <a className="nav-link" href="/signup">Sign Up</a>
            </li>
        </Fragment>
    );

    const authLinks = () => (
      <Fragment>
        <li className='nav-item'>
            <a className='nav-link' href='/select-product'>Product</a>
        </li>
        <li className='nav-item'>
            <a className='nav-link' href='/login' onClick={logout_user}>Logout</a>
        </li>
      </Fragment>
    );
    return (
        <Fragment>
            <nav className='navbar navbar-expand-md navbar-light bg-light sticky-top'>
                <a href="/">
                    <img style={{ width: "20%", height: "20%" }} className="logo-navbar" src={logo} alt="Blue box with text that says SkyBuild AI."/>
                </a>
                <button
                    className='navbar-toggler'
                    type='button'
                    data-toggle='collapse'
                    data-target='#navbarNav'
                    aria-controls='navbarNav'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                >
                    {'\u2261'}
                    {/* <span className='navbar-toggler-icon'></span> */}
                </button>
                <div className='collapse navbar-collapse' id='navbarNav'>
                    <ul className='navbar-nav ml-auto'>
                        <li className='nav-item active'>
                            <a className="nav-link" href="/">Home <span className='sr-only'>(current)</span></a>
                        </li>
                        {isAuthenticated ? authLinks() : guestLinks()}
                    </ul>
                </div>
            </nav>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logout })(Navbar);

        // <div className="bg-blue-700 sticky top-0 z-50">
        //     <div className="container mx-auto">
        //         <nav className="flex items-center justify-between h-20 bg-blue-700">
        //         <a href="/" className="text-white font-bold text-lg">
        //             <img
        //             src={logo}
        //             alt="Blue box with text that says SkyBuild AI."
        //             className="logo-in-navbar"
        //             />
        //         </a>
        //         {/* Conditional rendering of the toggle button */}
        //         {window.innerWidth <= 640 ? (
        //             // Render the button for smaller screens
        //             <button
        //             className="text-white hover:text-gray-300"
        //             onClick={toggleMobileMenu}
        //             >
        //             &#8801;
        //             </button>
        //         ) : (
        //             // Render the nav links for larger screens
        //             <ul className="flex space-x-6 text-white">
        //             <li className="text-white">
        //                 <a href="/" className="hover:text-gray-300">
        //                 Home
        //                 </a>
        //             </li>
        //             {isAuthenticated ? (
        //                 <li>
        //                 <a
        //                     href="/select-product"
        //                     className="hover:text-gray-300"
        //                 >
        //                     Product
        //                 </a>
        //                 </li>
        //             ) : (
        //                 <Fragment>
        //                 <li>
        //                     <a href="/login" className="hover:text-gray-300">
        //                     Login
        //                     </a>
        //                 </li>
        //                 <li>
        //                     <a href="/signup" className="hover:text-gray-300">
        //                     Sign Up
        //                     </a>
        //                 </li>
        //                 </Fragment>
        //             )}
        //             </ul>
        //         )}
        //         </nav>
        //         {/* Mobile menu */}
        //         {isMobileMenuOpen && (
        //         <div className="bg-blue-700 text-white">
        //             <ul className="py-4">
        //             <li className="py-2">
        //                 <a href="/" className="block px-4 hover:bg-blue-600">
        //                 Home
        //                 </a>
        //             </li>
        //             {isAuthenticated ? (
        //                 <li className="py-2">
        //                 <a
        //                     href="/select-product"
        //                     className="block px-4 hover:bg-blue-600"
        //                 >
        //                     Product
        //                 </a>
        //                 </li>
        //             ) : (
        //                 <Fragment>
        //                 <li className="py-2">
        //                     <a href="/login" className="block px-4 hover:bg-blue-600">
        //                     Login
        //                     </a>
        //                 </li>
        //                 <li className="py-2">
        //                     <a href="/signup" className="block px-4 hover:bg-blue-600">
        //                     Sign Up
        //                     </a>
        //                 </li>
        //                 </Fragment>
        //             )}
        //             {/* Add more mobile menu items as needed */}
        //             </ul>
        //         </div>
        //         )}
        //     </div>
        //     </div>
