import React, { useState, useCallback, useRef, useEffect } from 'react';
import "../styles/design.css";
import axios from 'axios';
import { useFetch } from "../hooks/useFetch";
import { postDataWithAuth } from "../utils/api";
import PlanGPTChatbot from "./PlanGPTChatbot";
import FeedbackPopup from "./FeedbackPopup";
import pako from 'pako';

const PlanGPTForm = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [serverResponse, setServerResponse] = useState(null);
  const [productData, setProductData] = useState(false);
  const [finalSubmission, setFinalSubmission] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [url, setUrl] = useState("/plan-gpt");
  const [feedbackText, setFeedbackText] = useState("You will receive an email shortly with a report to help you understand your construction project. This aims to help guide your decision in pursuing the project.");
  const [formError, setFormError] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [showChatbot, setShowChatbot] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: { csrfToken }, loading } = useFetch('get-csrf-token');

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    setShowChatbot(false);
    setFormError(false);
  };

  //const analyzingResultsRef = useRef(null);
  //const productTableRef = useRef(null);

  const logFormData = () => {
    console.log("Image:", selectedImage);
  };

  const handleImageSubmit = useCallback(async () => {
    const formData = new FormData();
    if (!selectedImage) {
      setUrl("/lead-qual");
      setFeedbackText("Please select a document before uploading.");
      setFormError(true);
    }
    else {
      const filename = selectedImage.name;
      const reader = new FileReader();
      //formData.append('image', selectedImage, selectedImage.name);
      console.log("formData plangpt");
      console.log(formData);

      const accessToken = localStorage.getItem('access');
      console.log("access");
      console.log(accessToken);

      reader.onload = async function (event) {
        try {
          // Call the logFormData function before making the API request
          setIsLoading(true);
          setFinalSubmission(true);
          setSessionId(new Date().toISOString());
          logFormData();
          const compressedData = pako.gzip(event.target.result);
          const blob = new Blob([compressedData], { type: 'application/octet-stream' });
          formData.append('image', blob, selectedImage.name);
          const url = `plangpt/${filename}`;
          console.log(url)
          const response = await postDataWithAuth(url, formData, csrfToken, accessToken);
          if (response.data) {
            console.log("response plangpt form");
            console.log(response.data);
            console.log('success plangpt chatbot');
            console.log(response.data.status);
            if (response.data.status === "success") {
              setFinalSubmission(false);
              setProductData(true);
              setShowChatbot(true);
            }
          }
          console.log("Data Submitted");
          setIsFinished(true);
        } catch (error) {
          console.error('Error uploading image:', error);
          setFeedbackText("An error occurred in our system. Please try again.");
          setFormError(true);
        } finally {
          setIsLoading(false);
        }
      }
      reader.readAsArrayBuffer(selectedImage);
    // if (analyzingResultsRef.current) {
    //   analyzingResultsRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  }
  }, [csrfToken, selectedImage]);

  // useEffect(() => {
  //   if (finalSubmission && !productData) {
  //     analyzingResultsRef.current.scrollIntoView({ behavior: 'smooth' });
  //   } // else if (productData) {
  //   //   productTableRef.current.scrollIntoView({ behavior: 'smooth' });
  //   // }
  // }, [finalSubmission, productData]);


  if (loading || !csrfToken) return null;

  return (
    <div className="plangptform">
      <div className="design-container">
        <h2>Upload your Design / Drawing</h2>
        <input type="file" accept=".pdf" onChange={handleImageUpload} />
        <button onClick={handleImageSubmit}>Upload</button>
      </div>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0px',
            left: '0px',
            display: 'flex',
            background: 'rgba(0, 0, 0, 0.64)',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '27px',
          }}
        >
          {/* Your loading indicator here */}
          Loading...
        </div>
      )}
      {formError && <FeedbackPopup text={feedbackText} trigger={formError} setTrigger={setFormError} url={url} />}
      {showChatbot && productData && 
      (
          <div className="plangptchatbot">
            <PlanGPTChatbot sessionId={sessionId} />
          </div>
        )
      }
      {formError && <FeedbackPopup text={feedbackText} trigger={formError} setTrigger={setFormError} url={url} />}
    </div>
  );
};

export default PlanGPTForm;
