import React, { Component } from 'react';
import SummarizerForm from "./SummarizerForm";

function Summarizer() {
    return ( 
      <div className="summarizer">
        <SummarizerForm />
      </div>
    );
  }
  
  export default Summarizer;
  