import { useState, useEffect, useRef, useCallback, useContext, } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {useFetch} from "../hooks/useFetch";
import {postData} from "../utils/api";
import { FormContext } from "./StepperForm";
import FeedbackPopup from "./FeedbackPopup";
import "../styles/projectformquestion.css";

function ProjectFormQuestion({ question, formLength, setResponseData}) {
    const [url, setUrl] = useState("/sselect-material");
    const [feedbackText, setFeedbackText] = useState("You will receive an email shortly with a report to help you understand your construction project. This aims to help guide your decision in pursuing the project.");
    const [formError, setFormError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const {
      activeStepIndex,
      setActiveStepIndex,
      formData,
      setFormData,
    } = useContext(FormContext);

    let siteAddress = "";
    let state = "";
    let siteZipcode = "";
    let productCategory = "";

    console.log("ProjectFormQuestion TEST formLength");
    console.log(formLength);

    //const resultsRef = useRef(null);

    const [finalSubmission, setFinalSubmission] = useState(false);
  
    const initialValues = {
      [question]: ''
    };
  
    const validationSchema = Yup.object().shape({
      [question]: Yup.string().notRequired()
    });

    const { data: { csrfToken }, loading } = useFetch('get-csrf-token');

    useEffect(() => {
      // if (finalSubmission && resultsRef.current) {
      //   window.scrollTo({
      //       top: resultsRef.current.offsetTop,
      //       behavior: "smooth"  // Optional, this makes the scrolling smooth
      //   });
      // }
        console.log("Updated formData:", formData);
        siteAddress = localStorage.getItem('siteAddress');
        state = localStorage.getItem('state');
        siteZipcode = localStorage.getItem('siteZipcode');
        productCategory = localStorage.getItem('productCategory');
        const newValues = {
            "What is the site's street address?": siteAddress,
            "What state is the site in?": state,
            "What is the site's zipcode?": siteZipcode,
            "productCategory": productCategory
        };
        
        setFormData((prev) => ({ ...prev, ...newValues }));
      }, [finalSubmission]);      
  
    const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
      // Merge the current question's answer into the formData
      setFormData((prev) => ({ ...prev, ...values }));
  
      //If it's the last question
      if (activeStepIndex === formLength - 1) {
        try {
          setIsLoading(true);
          setFinalSubmission(true);
          const response = await postData('get_product_recommendation', formData, csrfToken);
          setActiveStepIndex((prev) => prev + 1);
          setResponseData(response.data.formatted_responses);
          console.log("Data Submitted");
          console.log(response.data.formatted_responses);
          setIsFinished(true);
        } catch (error) {
          console.error("Error submitting the form:", error);
          setFeedbackText("An error occurred in our system. Please try again.");
          setFormError(true);
        } finally {
          setIsLoading(false);
        }
      } else {
        // Move to the next question
        setActiveStepIndex((prev) => prev + 1);
        resetForm();
      }
      
      // Finish form submission
        setSubmitting(false);
    };

  return (
    <Formik
        key={activeStepIndex}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
      <Form className="tw-flex tw-flex-col tw-justify-center tw-items-center">
            <div className="tw-text-2xl tw-font-medium tw-self-center tw-mb-2">Help us understand your project!</div>
            <div className="tw-flex tw-flex-col tw-items-start tw-mb-2">
                <label className="tw-font-medium tw-text-gray-900">{question}</label>
                <Field
                    name={question}
                    className="tw-rounded-md tw-border-2 tw-p-2 tw-w-full"
                    disabled={finalSubmission}
                />
                <ErrorMessage name={question}/>
            </div>
            <button
                className="tw-rounded-md tw-bg-indigo-500 tw-font-medium tw-text-white tw-my-2 tw-p-2"
                type="submit"
                disabled={finalSubmission}
            >
                Continue
            </button>
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: '0px',
                  left: '0px',
                  display: 'flex',
                  background: 'rgba(0, 0, 0, 0.64)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '27px',
                }}
              >
                {/* Your loading indicator here */}
                Loading...
              </div>
            )}
            {formError && <FeedbackPopup text={feedbackText} trigger={formError} setTrigger={setFormError} url={url} />}
            {/* {finalSubmission && <h3 ref={resultsRef} className="loading-results">Analyzing results...</h3>} */}
      </Form>
    </Formik>
  );
}

export default ProjectFormQuestion;
