import React from 'react';
import "../styles/about.css";
import home from "../images/home.jpg";

const About = () => {
    return (
      <div className="interior-painting">
        <div className="image-container">
          <img className="background-image" src={home} alt="Construction blueprints and a hard hat" />
        </div>
        <div className="text-container">
          <div className="text-box">
            <h1 className="title-about">About SkyBuild AI</h1>
            <p className="description-about">
              We are revolutionizing how we build the world, starting with a paradigm shift in how the construction industry ingests highly technical construction documents and democratizing architectural & structural engineering blueprints.
            </p>
            <div className="about-button">
              <a href="/signup" className="signup-button">Sign Up</a>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default About;
