import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';
import Navbar from "./components/Navbar";
import Home2 from "./components/Home2";
import Login from './components/Login';
import Signup from './components/Signup';
import Activate from './components/Activate';
import ResetPassword from './components/ResetPassword';
import ResetPasswordConfirm from './components/ResetPasswordConfirm';
import Google from './components/Google';
import Product from './components/Product';
import CentralProduct from './components/CentralProduct';
import CentralProduct2 from './components/CentralProduct2';
import Design from './components/Design';
import ManufactureDesign from './components/ManufactureDesign';
import Services from './components/Services';
import { Provider } from 'react-redux';
import store from './store';
import ProductType from './components/ProductType';
import StepperForm from './components/StepperForm';
import PlanGPT from './components/PlanGPT';
import Summarizer from './components/Summarizer';
import LeadQual from './components/LeadQual';
import ProtectedRoute from './components/ProtectedRoute';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfConditions from './components/TermsOfConditions';

import Layout from './hocs/Layout';

const App = () => (
    <Provider store={store}>
        <Router>
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home2} />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/signup' component={Signup} />
                    <Route exact path='/google' component={Google} />
                    <Route exact path='/reset-password' component={ResetPassword} />
                    <Route exact path='/password/reset/confirm/:uid/:token' component={ResetPasswordConfirm} />
                    <Route exact path='/activate/:uid/:token' component={Activate} />
                    <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                    <Route exact path='/terms-and-conditions' component={TermsOfConditions} />
                    <ProtectedRoute exact path='/select-product' component={CentralProduct2} />
                    <ProtectedRoute exact path='/select-material' component={Services} />
                    <ProtectedRoute exact path='/upload-design' component={Design} />
                    <ProtectedRoute exact path='/upload-design-manufacture' component={ManufactureDesign} />
                    <ProtectedRoute exact path='/select-material-category' component={ProductType} />
                    <ProtectedRoute exact path='/project-form' component={StepperForm} />
                    <ProtectedRoute exact path='/plan-gpt' component={PlanGPT} />
                    {/* <ProtectedRoute exact path='/summarizer' component={Summarizer} /> */}
                    <ProtectedRoute exact path='/lead-qual' component={LeadQual} />
                </Switch>
            </Layout>
        </Router>
    </Provider>
);

export default App;
