import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/centralproduct.css";

function CentralProduct2() {
    const [selectedUserType, setSelectedUserType] = useState(null);
    const handleUserTypeSelection = (userType) => () => {
        setSelectedUserType(userType);
        localStorage.setItem('userType', userType);
    }

    return (
        <div className="product">
            <h2 className="central-product-title">How Can We Help You?</h2>
            <div className="central-product-description">As a General Contractor, you may often be bogged down with going through lengthy construction documents to determine if you can pursue the project given your background and talents. With LeadQual, we'll help you get up to speed on the construction project so you can make the decision faster. With PlanGPT, you can upload a construction document and ask technical questions about the document to further clarify any questions. If you are in the planning stages of a project, and would like to select specific materials products, fill out our Recommendations form and see products right away that fit your project's requirements. Try out the Bill Of Materials module to receive a list of material product recommendations by uploading a construction document.</div>
            <div className="buttons-container">
                <Link to="/lead-qual">
                    <button className="user-type-button">
                        LeadQual
                    </button>
                </Link>
                <Link to="/plan-gpt">
                    <button className="user-type-button">
                        PlanGPT
                    </button>
                </Link>
            </div>
            <div className="types-of-users">
                <div>
                    <Link to="/select-material">
                        <button className="user-type-button" onClick={handleUserTypeSelection("Architect/Engineer")}>
                            Recommendations
                        </button>
                    </Link>
                </div>
                <div>
                    <Link to="/upload-design">
                        <button className="user-type-button">
                            Bill Of Materials
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CentralProduct2;
