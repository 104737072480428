import { createContext, useState } from "react";
import Step from "./Step";
import Stepper from "./Stepper";
import "../styles/stepperform.css";
import "../styles/tailwind.css";
export const FormContext = createContext();

function StepperForm() {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [responseData, setResponseData] = useState([]);


  let fields = JSON.parse(localStorage.getItem('formFieldQuestions'));

  console.log("TEST fields");
  console.log(fields);
  const numberOfFields = Object.keys(fields).length;
  console.log("numberOfFields:");
  console.log(numberOfFields);
  console.log("fields:");
  console.log(Array.isArray(fields));
  console.log(fields);

  return (
    <div className="tw">
      <FormContext.Provider
      value={{ activeStepIndex, setActiveStepIndex, formData, setFormData }}
    >  
        <div className="tw-w-screen tw-h-screen tw-flex tw-flex-col tw-items-center tw-justify-start">
            <Stepper formLength={numberOfFields}/>
            <Step fields={fields} formLength={numberOfFields} responseData={responseData} setResponseData={setResponseData}/>
        </div>
      </FormContext.Provider>
    </div>
  );
}

export default StepperForm;
