import React, { useState, useEffect, useRef } from 'react';
import "../styles/chatbotanalysis.css";
import PlanGPTForm from "./PlanGPTForm";
import { useCallback } from "react";
import { useFetch } from "../hooks/useFetch";
import { postDataWithAuth } from "../utils/api";

function PlanGPTChatbot({ response, sessionId }) {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [isTextBoxEnabled, setIsTextBoxEnabled] = useState(true);

    const messagesEndRef = useRef(null);

    const { data: { csrfToken }, loading } = useFetch('get-csrf-token');
    const accessToken = localStorage.getItem('access');

    useEffect(() => {
        if (sessionId) {
          // Clear messages for a new session
          setMessages([]);
          // Add a welcome message
          setMessages([{ type: 'bot', text: 'We have analyzed your document. You may ask any question about the document and we will try our best to support you.' }]);
        }
      }, [sessionId]);

    useEffect(() => {
        if (response) {
            setIsWaitingForResponse(false);
            if(typeof response === "object" && response.text){
                setMessages(prevMessages => [
                    ...prevMessages,
                    response
                ]);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { type: 'bot', text: response }
                ]);
            }
        }
    }, [response]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [messages]);    

    const handleSend = useCallback(async (inputValue) => {
        const formData = new FormData();
        try {
            if (inputValue.trim()) {
                // Add a temporary message
                setMessages(prevMessages => [
                    ...prevMessages,
                    { type: 'user', text: inputValue },
                    { type: 'bot', text: 'One moment...' }  // Temporary message
                ]);
                // Set a flag to indicate waiting for a real response
                setIsWaitingForResponse(true);
                setIsTextBoxEnabled(false);
                formData.append('flag', 'false');
                formData.append('value', inputValue);
                console.log("inputValue");
                console.log(inputValue);
                //sendData = { "flag": "false", "value": inputValue };
                const response = await postDataWithAuth('plangptchatbot', formData, csrfToken, accessToken);
                // setMessages(prevMessages => [
                //     ...prevMessages,
                //     { type: 'user', text: inputValue }
                // ]);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { type: 'bot', text: response.data["formatted_responses"] }
                ]);
                setInputValue('');
                setIsWaitingForResponse(false);
            }
        } catch (error) {
            console.error(error);
            setMessages(prevMessages => [
                ...prevMessages,
                { type: 'bot', text: "There was an issue. Please try again or enter a different question." }
            ]);
            setIsTextBoxEnabled(true);
        } finally {
            setIsWaitingForResponse(false); // Reset the flag after handling the response
            setIsTextBoxEnabled(true);
          }
    }, [csrfToken]);

    return (
        <div className={'chatbot-container2 open'}>
            <div className="chatbot-header2">Chatbot</div>
                    <div className='messages2 open' ref={messagesEndRef}>
                        {/* {messages.map((message, index) => (
                            <div key={index} className={`message ${message.type}`}>
                                <strong>{message.type === 'user' ? 'Me:' : 'SkyGuide:'}</strong> <br /> {message.text}
                            </div>
                        ))} */}
                        {messages.map((message, index) => (
                            <div key={index} className={`message2 ${message.type}`}>
                                <strong>{message.type === 'user' ? 'Me:' : 'SkyGuide:'}</strong> <br />
                                {message.isHtml ? (
                                    <span dangerouslySetInnerHTML={{ __html: message.text }} />
                                ) : (
                                    message.text
                                )}
                            </div>
                        ))}
                        {/* <div ref={messagesEndRef}></div> */}
                    </div>
                    <div className={'input-container2 open'}>
                        <textarea
                            className="input2"
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            placeholder="Type your message..."
                            rows={5}
                            onKeyDown={e => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSend(inputValue);
                                }
                            }}
                            disabled={isWaitingForResponse || !isTextBoxEnabled}
                        />
                        <button onClick={() => handleSend(inputValue)}>Send</button>
                    </div>
        </div>
    );
}

export default PlanGPTChatbot;
