import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password } from '../actions/auth';
import FeedbackPopup from "./FeedbackPopup";
import "../styles/authentication.css";

const ResetPassword = ({ reset_password }) => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        email: ''
    });
    const [submitEmail, setSubmitEmail] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");
    const [url, setUrl] = useState("/login");

    const { email } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        setFeedbackText("You will receive an email to reset your password, if your account exists with us.")
        setSubmitEmail(true);
        reset_password(email);
        setRequestSent(true);
    };

    // if (requestSent) {
    //     return <Redirect to='/login' />
    // }

    return (
        <div className='container mt-5'>
            <h1>Request Password Reset:</h1>
            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='email'
                        placeholder='Email'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                    />
                </div>
                <button className='btn btn-primary' type='submit'>Reset Password</button>
                {submitEmail && <FeedbackPopup text={feedbackText} trigger={submitEmail} setTrigger={setSubmitEmail} url={url} />}
            </form>
        </div>
    );
};

export default connect(null, { reset_password })(ResetPassword);