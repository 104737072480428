import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import SingleSelectDropdown from './SingleSelectDropdown';
import "../styles/services.css";
  
function Services() {
    
    return ( 
      <div className="services">
        <div className="services-title">
            Select Material Type
        </div>
        <div className="services-dropdown">
            <SingleSelectDropdown />
        </div>
      </div>
    );
  }
  
  export default Services;
