import React, { Component } from 'react';
import "../styles/value.css";

function Value() {

    return ( 
      <div className="value-sbai">
        <div className="value-title">
          SkyBuild AI Is An Emerging Construction Powerhouse.
        </div>
        <div className="value-subheader">
          We strive to empower AEC professionals daily, so that they can empower their clients, and build smarter.
        </div>
        <div className="value-text">
          <ul>
            <li>Construction + Tech + AI Leaders</li>
            <li>Building Reliable Generative AI in the Construction Space</li>
            <li>Providing Productivity Insights, Faster Decisions, Smarter Outcomes</li>
            <li>Collaborative & Constructive Care For Your Projects</li>
          </ul>
        </div>
      </div>
    );
  }
  
  export default Value;
  