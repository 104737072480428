import React, { useState, useCallback, useRef, useEffect } from 'react';
import "../styles/design.css";
import axios from 'axios';
import { useFetch } from "../hooks/useFetch";
import { postDataWithAuth } from "../utils/api";
import ProductTableManufacturer from "./ProductTableManufacturer";
import FeedbackPopup from "./FeedbackPopup";

const USStates = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
  'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
  'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
  'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
  'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

const ManufactureDesign = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [serverResponse, setServerResponse] = useState(null);
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
//  const [siteAddress, setSiteAddress] = useState('');
//  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [manufacturerName, setManufacturerName] = useState('');
  const [finalSubmission, setFinalSubmission] = useState(false);
  const [url, setUrl] = useState("/upload-design");
  const [feedbackText, setFeedbackText] = useState("You will receive an email shortly with a report to help you understand your construction project. This aims to help guide your decision in pursuing the project.");
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const { data: { csrfToken }, loading } = useFetch('get-csrf-token');

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    setProductData([]);
    setFormError(false);
  };

//  const analyzingResultsRef = useRef(null);
  const productTableRef = useRef(null);

  const logFormData = () => {
    console.log("Image:", selectedImage);
//    console.log("Site Address:", siteAddress);
//    console.log("State:", state);
    console.log("Zipcode:", zipcode);
    console.log("Manufacturer Name:", manufacturerName);
  };

  useEffect(() => {
    if (productData.length > 0) {
      productTableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [finalSubmission, productData]);

  const handleImageSubmit = useCallback(async () => {
    const formData = new FormData();
    const filename = selectedImage.name;
    formData.append('image', selectedImage, selectedImage.name);
//    formData.append('site_address', siteAddress);
//    formData.append('state', state);
    formData.append('zipcode', zipcode);
    formData.append('manufacturer_name', manufacturerName); // Include manufacturer name

    const accessToken = localStorage.getItem('access');

    try {
      // Call the logFormData function before making the API request
      setIsLoading(true);
      setFinalSubmission(true);
      logFormData();
      const url = `uploaddesignmanufacture/${filename}`;
      console.log(url)
      const response = await postDataWithAuth(url, formData, csrfToken, accessToken);
      if (response.data && response.data.response_content) {
        console.log(response.data)
        console.log('here')
        console.log(response.data.response_content)
        setProductData(response.data.response_content);
      }
      if (response.data && response.data.category_and_product_info) {
        setCategoryData(response.data.category_and_product_info);
        console.log('category data');
        console.log(response.data.category_and_product_info);
      }
      console.log("Data Submitted");
      setIsFinished(true);
    } catch (error) {
      console.error('Error uploading image:', error);
      setFeedbackText("An error occurred in our system. Please try again.");
      setFormError(true);
    } finally {
      setIsLoading(false);
    }
    // if (analyzingResultsRef.current) {
    //   analyzingResultsRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  }, [csrfToken, selectedImage, zipcode, manufacturerName]); // Include manufacturerName


  if (loading || !csrfToken) return null;

  // function ProductTable({ data }) {
  //     console.log(data)
  //     return (
  //       <table className="product-table">
  //         <thead>
  //           <tr>
  //             <th>Name</th>
  //             <th>Approximate Price</th>
  //             <th>Usecase</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {data.map((product, index) => (
  //             <tr key={index}>
  //               <td>{extractNameAndSKU(product.name)}</td>
  //               <td>{product.approximate_price.replace(",", "")}</td>
  //               <td>{product.usecase}</td>
  //               <td><button>Buy</button></td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     );
  //   }


  return (
    <div className="design-container">
      <h2>Upload your Design / Drawing</h2>
      <input type="file" accept=".pdf" onChange={handleImageUpload} />
      {/* <input type="text" value={state} onChange={(e) => setState(e.target.value)} /> */}

      <h4>Zipcode</h4>
      <input type="text" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />

      <h4>Manufacturer Firm Name</h4> {/* New field */}
      <input type="text" value={manufacturerName} onChange={(e) => setManufacturerName(e.target.value)} />

      <button onClick={handleImageSubmit}>Upload</button>

      {isLoading && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0px',
              left: '0px',
              display: 'flex',
              background: 'rgba(0, 0, 0, 0.64)',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '27px',
            }}
          >
            {/* Your loading indicator here */}
            Loading...
          </div>
        )}

      {/* {finalSubmission && productData.length === 0 && <h3 className="loading-results" ref={analyzingResultsRef}>Analyzing results...</h3>} */}

      {productData.length > 0 && (
        <div className="products-list" ref={productTableRef}>
          <ProductTableManufacturer categoryAndProductInfo={categoryData} />
        </div>
      )}
      {formError && <FeedbackPopup text={feedbackText} trigger={formError} setTrigger={setFormError} url={url} />}
    </div>
  );
};

export default ManufactureDesign;
