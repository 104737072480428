import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
// import "../styles/footer.css";

export default function Footer() {
  return (
    <div className="footer">
        <MDBFooter bgColor='dark' className='text-center text-lg-left'>
        <div className='text-center p-3' style={{ backgroundColor: '#BABFD1' }}>
            <a className='text-black' href='/'>
            SkyBuild AI{' '}
            </a>
            &copy; {new Date().getFullYear()} Copyright:{' '}
            {/* <a className='text-black' href='https://mdbootstrap.com/'>
            MDBootstrap.com{' '}
            </a> */}
            <a className='text-black' href='/privacy-policy'>
            Privacy Policy | {' '}
            </a>
            <a className='text-black' href='/terms-and-conditions'>
            Terms and Conditions{' '}
            </a>
        </div>
        </MDBFooter>
    </div>
  );
}
