import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useSpring, animated, config } from 'react-spring';

const Vision = () => {
  const [isVisible, setIsVisible] = useState(false);
  const styleProps = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(0, 40px, 0)',
    config: config.molasses
  });

  const onEnterViewport = () => {
    setIsVisible(true);
  };

  const onLeaveViewport = () => {
    setIsVisible(false);
  };

  const text = "Our goal is to make the construction of homes, offices, hospitals, schools, and more, accessible to everyone. By streamlining the process, professionals can prioritize the safety of building occupants, enhance construction care for property owners, and contribute to a more sustainable planet. We eliminate manual tasks to offer an efficient productivity solution for professionals in the architecture, engineering, and construction (AEC) industry.";

  const gradientBackground = {
    background: 'linear-gradient(90deg, #BABFD1, #8C91B6)', // Add linear gradient here
    padding: '24px',
    height: 'auto',
    paddingTop: '5%',
    paddingLeft: '10%',
    paddingRight: '10%',
  };

  return (
    <Waypoint onEnter={onEnterViewport} onLeave={onLeaveViewport}>
      <animated.div style={{ ...styleProps, ...gradientBackground }}>
        <p style={{ color: 'black', fontFamily: 'Roboto', fontSize: '2em', textAlign: 'center', fontWeight: 'bold' }}>Our Vision</p>
        <p style={{ color: 'black', fontFamily: 'Roboto', fontSize: '1.5em', justifyContent: 'center' }}>{text}</p>
      </animated.div>
    </Waypoint>
  );
};

export default Vision;
