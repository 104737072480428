import React, { Component } from 'react';
import "../styles/intro.css";
import home from "../images/home.jpg";

function Intro() {

    return ( 
      <div className="intro">
        <div className="intro-row">
          We are revolutionizing how we build the world, starting with a paradigm shift in how the construction industry sources and procures building products.
          <br></br>
          Our vision is to enable users to seamlessly convert engineering blueprints into a list of required products that meet the specific needs of the project while ensuring the best product-project fit and lowest price.
        </div>
        <div className="intro-row">
          <img style={{ width: "450px", height: "300px", borderRadius: "5px", marginTop: "3%" }} src={home} alt="Home under construction"/>
        </div>
      </div>
    );
  }
  
  export default Intro;
  