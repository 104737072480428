import React, { Component, useState, useEffect } from 'react';
import "../styles/hero.css";
import Container from 'react-bootstrap/Container';
import hero_image from "../images/hero_background.jpg";
import { FcOk } from "react-icons/fc";
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBContainer
} from 'mdb-react-ui-kit';
import { useSpring, animated } from 'react-spring';

function Hero2() {
  const [bulletPoints, setBulletPoints] = useState([
    'Analyze your construction drawings with our Structural Engineering AI Assistant',
    'Receive Productivity Insights, Make Faster Decisions, Have Smarter Outcomes',
    'Takes minutes, instead of hours. Sent straight to your email.',
  ]);

  const [currentItemIndex, setCurrentItemIndex] = useState(-1);


  useEffect(() => {
    const interval = setInterval(() => {
      if (currentItemIndex < bulletPoints.length - 1) {
        setCurrentItemIndex(currentItemIndex + 1);
      }
    }, 1500);

    return () => clearInterval(interval);
  }, [currentItemIndex, bulletPoints]);

  return (
    <div className="hero-section">
      <img src={hero_image} alt="Hero image" className="hero-image" />

      <div className="hero-text-top-left">
        <h1 className="hero-text-header">AI Is In Our DNA</h1>
        <p className="hero-top-left-subtext-1">Construction Building Project Documents Ingested & Decoded In Minutes</p>
        <p className="hero-top-left-subtext-2">Win Projects Smarter with SkyBuild AI. Safe, Smart, and Sustainable.</p>
      </div>

      <div className="hero-text-bottom-right">
        <ul className="bullet-points">
          {bulletPoints.map((bulletPoint, index) => (
            <li
              key={index}
              className={`bullet-point ${currentItemIndex >= index ? 'active' : ''}`}
            >
              <FcOk className="checkmark-icon" /> {bulletPoint}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Hero2;
