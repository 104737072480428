import React, { useState, useCallback, useEffect } from 'react';
import "../styles/producttable.css";
import {useFetch} from "../hooks/useFetch";
import {postData} from "../utils/api";
import ChatbotWidget from './ChatbotWidget';

function ProductTable({ products, showManufacturer, categoryData }) {
    const [clickedProduct, setClickedProduct] = useState(null);
    const [chatbotOpen, setChatbotOpen] = useState(false);
    const [chatbotResponse, setChatbotResponse] = useState("");
    const [userEmail, setUserEmail] = useState('');
    const [envScores, setEnvScores] = useState({});

    const userType = localStorage.getItem('userType');
    const productCategory = localStorage.getItem('productCategory');
    const { data: { csrfToken }, loading } = useFetch('get-csrf-token');

    console.log("userType");
    console.log(userType);

    useEffect(() => {
        setUserEmail(localStorage.getItem('userEmail'));
    }, []);

    const toggleChatbot = () => {
        setChatbotOpen(!chatbotOpen);
    };

    useEffect(() => {
        const newEnvScores = products.map(() => 
            env_score[Math.floor(Math.random() * env_score.length)]
        );
        setEnvScores(newEnvScores);
    }, [products]);
    

    // const extractNameAndSKU = (productName) => {
    //     const regex = /^(.*?, SKU: \d+)/;
    //     const match = productName.match(regex);
    //     return match ? match[0] : productName;
    // };

    
    const extractNameAndSKU = (productName) => {
        const regex = /^(.*?)(?=, Approximate Price:)/;
        const match = productName.match(regex);
        return match ? match[0] : productName;
    };
    

    const handleProductClick = useCallback(async (value) => {
        try {
            // Toggle chatbot if it's not open
            if (!chatbotOpen) {
                toggleChatbot();
            }
    
            // Initially set the chatbot response to the loading message
            // setChatbotResponse("We're retrieving information about this product. One moment...");
            
            // Set clicked product
            setClickedProduct(value);

            // Immediately send the "Retrieving your product information..." message to the chatbot
            setChatbotResponse({ type: 'bot', text: "Retrieving your product information..." });

    
            // Make the API call
            let sendData = {"flag": "productInformation", "value": value};
            const response = await postData('chatbot_project_based', sendData, csrfToken);
    
            // Log the response
            console.log("Chatbot Data Submitted");
            console.log(response.data);

            // Process the chatbot response
            // let reformattedResponse = response.data["formatted_responses"]
            // .replace(/- /g, '• ')  // Replace hyphen bullet points with dot bullet points
            // .replace(/(Product Name:|Description:|Usage:|Application:|Advantages:|Disadvantages:)/g, '<span style="color: black;"><strong>$1</strong></span>')  // Make the fields bold and black
            // .split('\n')  // Split the text by newlines
            // .join('<br/>');  // Join them back with HTML breaks for new lines

            // let reformattedResponse = response.data["formatted_responses"]
            // .replace(/- /g, '• ')  // Replace hyphen bullet points with dot bullet points
            // .replace(/(Product Name:|Description:|Usage:|Application:|Advantages:|Disadvantages:)/g, '<strong>$1</strong>')  // Make the fields bold
            // .split('\n')  // Split the text by newlines
            // .join('<br/>');  // Join them back with HTML breaks for new lines

            let reformattedResponse = response.data["formatted_responses"]
            .replace(/- /g, '• ')  
            .replace(/(Product Name:|Description:|Usage:|Application:|Advantages:|Disadvantages:)/g, '<span style="color: black;"><strong>$1</strong></span>')  
            .split('\n')  
            .join('<br/>');

            setChatbotResponse({type: 'bot', text: reformattedResponse, isHtml: true});
        
            // Set the chatbot response with the data received from the API
            // setChatbotResponse(response.data["formatted_responses"]);
    
        } catch (error) {
            console.error(error);
            setChatbotResponse("Sorry, an error occurred. Please try again later.");
        }
    }, [chatbotOpen, csrfToken, toggleChatbot]);
    

    function formatNameDescription(inputString) {
        // Split the string using the regular expression pattern
        const stores = inputString.split(/\s*[a-z]\.\s*/);
    
        // Filter out empty strings and then join using newline
        return stores.filter(store => store.trim() !== "").join("\n");
    }

    const extractPrice = (priceString) => {
        const match = priceString.match(/\$\d+(\.\d{2})?/);
        return match ? match[0] : null;
    }

    const formatProductsToString = (products) => {
        if (userType === "Manufacturer") {
            return products.map(product => 
                `Name: ${product.name} | Usecase: ${product.usecase}\n`
            ).join('\n');
        }
        else if (userType === "General Contractor") {
            return products.map(product => 
                `Name: ${product.title} | Types: ${product.type} | Brands: ${product.brands}\n`
            ).join('\n');
        }
    };    
    
    console.log("products");
    console.log(products);
    console.log("formattedProducts");
    const formattedProducts = formatProductsToString(products);
    console.log(formattedProducts);
    

    const handleGenerateReport = () => {
        // Construct the email body. Assuming products is an array of strings.
        const emailBody = formatProductsToString(products);
      
        // Create the mailto link
        const mailtoLink = `mailto:${userEmail}?subject=SkyBuild AI - Bill of Materials Report&body=${encodeURIComponent(emailBody)}`;
      
        // Open the user's email client
        window.location.href = mailtoLink;
      };
      
    const env_score =  ["Very Good", "Good", "Fair"];

    const renderRow = (product, index) => {
        const productEnvScore = envScores[index];
        if (userType === "Architect/Engineer") {
            return (
                <tr key={index} className="product-row">
                    <td>
                        <button
                            className="product-link"
                            onClick={() => handleProductClick(product.name_description)}
                        >
                            {product.name_description}
                        </button>
                    </td>
                    {/* <td>{productEnvScore}</td> */}
                    <td className="product-store">{formatNameDescription(product.stores_price.replace("Retail Stores: ", "")).split("\n").map((store, index) => (
                        <span key={index}>
                            {store}
                            <br />
                            <br />
                        </span>
                        ))}
                    </td>
                    {/* <td></td>
                    <td><button className="buy-product">Buy</button></td> */}
                </tr>
            );
        } else if (userType === "Manufacturer") {
            return (
                <tr key={extractNameAndSKU(product.name)}>
                    <td>{extractNameAndSKU(product.name)}</td>
                    {/* <td>{productEnvScore}</td> */}
                    {/* <td>{product.approximate_price.replace(",", "")}</td> */}
                    <td>{product.usecase}</td>
                    {/* <td></td>
                    <td><button>Buy</button></td> */}
                </tr>
            );
        } else if (userType === "General Contractor") {
            return (
                <tr key={product.title.includes(":") ? product.title.replace(":", "") : product.title}>
                    <td>{product.title.includes(":") ? product.title.replace(":", "") : product.title}</td>
                    {/* <td>{productEnvScore}</td> */}
                    <td>{product.type}</td>
                    <td>{product.brands}</td>
                    {/* <td></td>
                    <td><button>Buy</button></td> */}
                </tr>
            );
        } 
    };

    if (!Array.isArray(products)) {
        return (
            <div>Please try again!</div>
        );
    }

    return (
        <div>
            <h3 className="rec-title">Recommendations</h3>
            <table className="product-table">
                <thead style={{ backgroundColor: '#143B5D' }}>
                    <tr style={{ color: 'white' }}>
                        {userType === "Architect/Engineer" && <>
                            <th>Name</th>
                            {/* <th>Environmental Score</th> */}
                            <th>Retail Stores & Address</th>
                            {/* <th>Quantity</th>
                            <th>Purchase</th> */}
                        </>}
                        {(userType === "Manufacturer") && <>
                            <th>Name</th>
                            {/* <th>Environmental Score</th> */}
                            <th>Use Case</th>
                            {/* <th>Quantity</th>
                            <th>Purchase</th> */}
                        </>}
                        {(userType === "General Contractor") && <>
                            <th>Title</th>
                            {/* <th>Environmental Score</th> */}
                            <th>Type</th>
                            <th>Brand Options</th>
                            {/* <th>Quantity</th>
                            <th>Purchase</th> */}
                        </>}
                    </tr>
                </thead>
                <tbody className="product-tbody">
                    {products.map((product, index) => renderRow(product, index))}
                </tbody>
            </table>
            {(userType != "Architect/Engineer") &&
                <div className="generate-report">
                    <h3>Generate a report of Bill of Materials</h3>
                    <button className="generate-report-button" onClick={handleGenerateReport}>Generate Report</button>
                </div>
            }
            <div>
                <ChatbotWidget response={chatbotResponse} isOpen={chatbotOpen} toggleChatbot={toggleChatbot} view={userType} />
            </div>
        </div>
    );
}

export default ProductTable;
