import React, { useState, useCallback, useRef, useEffect } from 'react';
import "../styles/design.css";
import axios from 'axios';
import { useFetch, useFetchForUser } from "../hooks/useFetch";
import { postDataWithAuth } from "../utils/api";
import PlanGPTChatbot from "./PlanGPTChatbot";
import FeedbackPopup from "./FeedbackPopup";
import pako from 'pako';
//import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.mjs'
//import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist';
//GlobalWorkerOptions.workerSrc = pdfjsWorker;
// import Worker  from '../utils/pdf.worker.mjs';
// GlobalWorkerOptions.workerSrc = './pdf.worker.mjs';

const LeadQual = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [serverResponse, setServerResponse] = useState(null);
  const [productData, setProductData] = useState(false);
  const [finalSubmission, setFinalSubmission] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [url, setUrl] = useState("/lead-qual");
  const [feedbackText, setFeedbackText] = useState("You will receive an email shortly with a report to help you understand your construction project. This aims to help guide your decision in pursuing the project.");
  const [formError, setFormError] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [file, setFile] = useState(null);

  const { data: { csrfToken }, loading } = useFetch('get-csrf-token');
  console.log("inside lead_qual.js");
  console.log(csrfToken);
  console.log(loading);
  const accessToken = localStorage.getItem('access');
  console.log("lead qual");
  console.log(accessToken);
  const { data: userData, loading: userLoading } = useFetchForUser('/auth/users/me/', accessToken);
  console.log("Test email data");
  console.log(userData.email);
  console.log(userLoading);

  useEffect(() => {
    if (userData && userData.email) {
      // You can use userData.email here
      console.log("Setting email");
      setEmail(userData.email);
      console.log("User email: ", userData.email);
    }
  }, [userData]);
  console.log("After email setting");

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    console.log("uploaded file");
    setFormError(false);
    //setProductData([]);
  };
  console.log("After pdf setting");

  const handleImageSubmit = useCallback(async () => {
    const formData = new FormData();
    if (!selectedImage) {
      setUrl("/lead-qual");
      setFeedbackText("Please select a document before uploading.");
      setFormError(true);
    }
    else {
      console.log("Inside handle image");
      const reader = new FileReader();
      const filename = selectedImage.name;
      console.log("filename", filename);
      console.log("has image?", selectedImage != null)
      //formData.append('image', selectedImage, selectedImage.name);
      formData.append('email', email);
      console.log("formData planGPT");
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      console.log("email", email);
      console.log("formData plangpt");
      console.log(formData);

      //const accessToken = localStorage.getItem('access');
      //console.log("access");
      //console.log(accessToken);

      reader.onload = async function (event) {
        try {
          setIsLoading(true);
          setFinalSubmission(true);
          setFeedbackText("You will receive an email shortly with a report to help you understand your construction project. This aims to help guide your decision in pursuing the project.");
          // Call the logFormData function before making the API request
          logFormData();
          const compressedData = pako.gzip(event.target.result);
          const blob = new Blob([compressedData], { type: 'application/octet-stream' });
          formData.append('image', blob, selectedImage.name);
          const url = `leadqual/${filename}`;
          console.log(url);
          console.log(formData);
          console.log(csrfToken);
          console.log(accessToken)
          const response = await postDataWithAuth(url, formData, csrfToken, accessToken);
          console.log("Response");
          console.log(response);
          if (response.data) {
            console.log("response plangpt form")
            console.log(response.data);
            console.log('success plangpt chatbot');
            console.log(response.data.status);
            if (response.data.status === "success") {
              setProductData(true);
            }
            // else if (response.data["status"] === "error") {
            //   setUrl("/lead-qual");
            //   setFeedbackText("An error occurred. Please try again.");
            //   setFormError(true);
            // }
          }
          console.log("Data Submitted");
          setIsFinished(true);
        } catch (error) {
          console.error('Error uploading image:', error);
          setFeedbackText("An error occurred in our system. Please try again.");
          setFormError(true);
        } finally {
          setIsLoading(false);
        }
    };
    reader.readAsArrayBuffer(selectedImage);
    // if (analyzingResultsRef.current) {
    //   analyzingResultsRef.current.scrollIntoView({ behavior: "smooth" });
    // }
    }
    }, [csrfToken, selectedImage]); // Include manufacturerName

  // const analyzingResultsRef = useRef(null);
  // const productTableRef = useRef(null);

  const logFormData = () => {
    console.log("Image:", selectedImage);
  };

  // async function getPdfText(data) {
  //   let doc = await getDocument({data}).promise;
  //   let pageTexts = Array.from({length: doc.numPages}, async (v,i) => {
  //       return (await (await doc.getPage(i+1)).getTextContent()).items.map(token => token.str).join('');
  //   });
  //   return (await Promise.all(pageTexts)).join('');
  // }

  // const getTextFromPDF = (pdfData) => {
  //   // This is a very basic example and may not work for all PDFs
  //   // You may need to implement a more sophisticated PDF parsing algorithm
  //   const decoder = new TextDecoder('utf-8');
  //   const text = decoder.decode(pdfData);
  //   return text;
  // };


  

  // const fetchPDFText = useCallback(async () => {
  //   try {
  //     setIsLoading(true);
  //     const data = new Uint8Array(await selectedImage.arrayBuffer());
  //     const filename = selectedImage?.name || '';
  //     console.log("TEST filename: ", filename);
  //     const newUrl = `leadqual/${filename}`;
  //     console.log(newUrl);
  //     const pdfText = await getPdfText(data);

  //     const formData = {
  //       filename,
  //       pdf_text: pdfText,
  //       email
  //     }
  //     console.log("TEST: about to send the axios call");
  //     const response = await postDataWithAuth(newUrl, formData, csrfToken, accessToken);
  //     // continue your logic here and call the backend api

  //     setIsFinished(true);

  //   } catch (error) {
  //     console.error('Error fetching PDF text:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // },[accessToken, csrfToken, selectedImage]);

  // const handleImageSubmit = () => {
  //   const file = selectedImage;

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onload = (e) => {
  //       const arrayBuffer = e.target.result;
  //       const uint8Array = new Uint8Array(arrayBuffer);

  //       // Check for a PDF header (first 4 bytes) to identify the file as a PDF
  //       const isPDF = uint8Array[0] === 0x25 && uint8Array[1] === 0x50 && uint8Array[2] === 0x44 && uint8Array[3] === 0x46;

  //       if (isPDF) {
  //         // Extract text content (very basic, may not work for all PDFs)
  //         const textContent = getTextFromPDF(uint8Array);
  //         // setPdfText(textContent);
  //         console.log(textContent);
  //       } else {
  //         // setPdfText('Not a valid PDF file');
  //         console.log("Not a valid PDF file");
  //       }
  //     };

  //     reader.readAsArrayBuffer(file);
  //   }
  // };


  if (loading || !csrfToken) return null;

  return (
    <div className="plangptform">
      <div className="design-container">
        <h2>Upload your Design / Drawing</h2>
        <input type="file" accept=".pdf" onChange={handleImageUpload} />
        <button onClick={handleImageSubmit}>Upload</button>
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0px',
              left: '0px',
              display: 'flex',
              background: 'rgba(0, 0, 0, 0.64)',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '27px',
            }}
          >
            {/* Your loading indicator here */}
            Loading...
          </div>
        )}
      </div>
      {isFinished && <FeedbackPopup text={feedbackText} trigger={isFinished} setTrigger={setIsFinished} url={url} />}
      {formError && <FeedbackPopup text={feedbackText} trigger={formError} setTrigger={setFormError} url={url} />}
    </div>
  );
};

export default LeadQual;



// import { FaCog } from "react-icons/fa";
// <FaCog className="loaderIcon"/>