import React, { Component } from 'react';
import Intro from "./Intro";
import Hero2 from "./Hero2";
import Benefits2 from "./Benefits2";
import Features2 from "./Features2";
// import Pricing from "./Pricing";
import CTA2 from "./CTA2";
import Footer from "./Footer";
import About from "./About";
import Vision from "./Vision";
import Value from "./Value";

function Home2() {

    return ( 
        <div className="home">
        <Hero2 />
        <About />
        <Vision />
        <Features2 />
        <Benefits2 />
        <Value />
        <CTA2 />
        <Footer />
      </div>
    );
  }
  
  export default Home2;
  