import React, { useContext, useEffect } from "react";
import { FormContext } from "./StepperForm";

function Stepper({ formLength }) {
  const { activeStepIndex } = useContext(FormContext);
  formLength = formLength + 1;

  useEffect(() => {
    const stepperItems = document.querySelectorAll(".tw-stepper-item");
    stepperItems.forEach((step, i) => {
      if (i <= activeStepIndex) {
        step.classList.add("tw-bg-indigo-500", "tw-text-white");
      } else {
        step.classList.remove("tw-bg-indigo-500", "tw-text-white");
      }
    });
  }, [activeStepIndex]);

  const renderSteps = () => {
    let steps = [];
    for (let i = 0; i < formLength; i++) {
      steps.push(
        <div key={`step-${i}`} className={`rounded-circle d-flex align-items-center justify-content-center ${i <= activeStepIndex ? "bg-primary text-white" : ""}`} style={{width: "2rem", height: "2rem", fontWeight: 500, border: "2px solid"}}>
            {i + 1}
        </div>
      );
      // Do not add a divider after the last item
      if (i < formLength - 1) {
        steps.push(
          <div key={`divider-${i}`} className="flex-grow-1" style={{borderTop: "2px solid", margin: "0 0.5rem"}}></div>

        );
      }
    }
    return steps;
  }

  return (
    <div className="tw-w-2/3 tw-flex tw-flex-row tw-items-center tw-justify-center tw-px-32 tw-py-16">
      {renderSteps()}
    </div>
  );
}

export default Stepper;



// import React, { useContext, useEffect } from "react";
// import { FormContext } from "./StepperForm";

// function Stepper({ formLength }) {
//   const { activeStepIndex } = useContext(FormContext);
//   useEffect(() => {
//     const stepperItems = document.querySelectorAll(".stepper-item");
//     stepperItems.forEach((step, i) => {
//       if (i <= activeStepIndex) {
//         step.classList.add("tw-bg-indigo-500", "tw-text-white");
//       } else {
//         step.classList.remove("tw-bg-indigo-500", "tw-text-white");
//       }
//     });
//   }, [activeStepIndex]);
//   return (
//     <div className="tw-w-2/3 tw-flex tw-flex-row tw-items-center tw-justify-center tw-px-32 tw-py-16">
//       <div className="stepper-item tw-w-8 tw-h-8 tw-text-center tw-font-medium tw-border-2 tw-rounded-full">
//         1
//       </div>
//       <div className="tw-flex-auto tw-border-t-2"></div>
//       <div className="stepper-item tw-w-8 tw-h-8 tw-text-center tw-font-medium tw-border-2 tw-rounded-full">
//         2
//       </div>
//       <div className="tw-flex-auto tw-border-t-2"></div>
//       <div className="stepper-item tw-w-8 tw-h-8 tw-text-center tw-font-medium tw-border-2 tw-rounded-full">
//         3
//       </div>
//     </div>
//   );
// }

// export default Stepper;
