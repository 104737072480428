import React, { useContext, useState, useRef, useEffect } from "react";
import { FormContext } from "./StepperForm";
import ProjectFormQuestion from "./ProjectFormQuestion";
import ProductTable from "./ProductTable";

function Step({ fields, formLength, responseData, setResponseData }) {
  const { activeStepIndex } = useContext(FormContext);

  const productTableRef = useRef(null);

  useEffect(() => {
    // Check if the ProductTable should be displayed and if so, scroll to it
    if (activeStepIndex === formLength && productTableRef.current) {
      window.scrollTo({
        top: productTableRef.current.offsetTop,
        behavior: "smooth"
      });
    }
  }, [activeStepIndex, formLength]);

  // Check if the current step index is valid.
  if (activeStepIndex < formLength) {
    const questionData = fields[activeStepIndex].label;
    console.log("type of label");
    console.log(typeof fields[activeStepIndex].label);
    console.log("questionData");
    console.log(questionData);
    return <ProjectFormQuestion question={questionData} formLength={formLength} setResponseData={setResponseData}/>;
  } else if (activeStepIndex === formLength) {
    console.log("response data to store into product table:");
    console.log(responseData);
    return (
        <div className="recommendation" ref={productTableRef}>
            <ProductTable products={responseData} />
        </div>
    );
  }
  
  // This is a fallback in case the activeStepIndex is out of bounds.
  return <div>Invalid step!</div>;
}

export default Step;
