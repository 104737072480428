import React from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import features_background from '../images/features_background.jpg'; // Import your background image

const boxData = [
  {
    title: 'Productivity Insights',
    description: 'Upload your construction document and receive a report with detailed project scope, taking into consideration structural engineering and building code regulations.',
  },
  {
    title: 'Faster Decisions',
    description: 'Ask technical questions to understand your project better. Our AI Assistant is your Technical Guru.',
  },
  {
    title: 'Smarter Outcomes',
    description: 'Save hours of time per new project. Win projects, faster.',
  },
];

const Features2 = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${features_background})`, // Set the background image
        backgroundSize: 'cover', // Adjust background size as needed
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        // minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column', // Center content vertically and add text above the grid
        justifyContent: 'center',
      }}
    >
      <Typography variant="h4" style={{ fontSize: '5.5vh', fontWeight: 'bold', color: 'white', marginTop: '5%', marginBottom: '5%' }}>
        What We Can Do For You
      </Typography>
      <Container maxWidth="md" style={{ marginBottom: '5%'}}>
        <Grid container spacing={0}>
          {boxData.map((box, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper elevation={3} style={{ padding: '7%', minHeight: '45vh', textAlign: 'center', backgroundColor: 'black' }}>
                <Typography variant="h4" color="white" marginBottom="3%">{box.title}</Typography>
                <Typography variant="body1" color="white" textAlign="left">{box.description}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Features2;
