import React, { useState, useEffect, useCallback, useRef } from 'react';
import "../styles/design.css";
import axios from 'axios';
import { useFetch } from "../hooks/useFetch";
import { postDataWithAuth } from "../utils/api";
import ProductTable from "./ProductTable";
import ProductTableManufacturer from "./ProductTableManufacturer";
import pako from 'pako';
import FeedbackPopup from './FeedbackPopup';

const USStates = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
  'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
  'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
  'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
  'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

const Design = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [serverResponse, setServerResponse] = useState(null);
  const [productData, setProductData] = useState([]);
  const [manufacturerName, setManufacturerName] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [finalSubmission, setFinalSubmission] = useState(false);
  const [showManufacturer, setShowManufacturer] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState('');
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDataLength, setCategoryDataLength] = useState(0);
  const [feedbackText, setFeedbackText] = useState("You will receive an email shortly with a report to help you understand your construction project. This aims to help guide your decision in pursuing the project.");
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const { data: { csrfToken }, loading } = useFetch('get-csrf-token');

  //const resultsRef = useRef(null);
  const productResultsRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    setProductData([]);
  };

  const handleUserTypeSelection = (userType) => () => {
    setSelectedUserType(userType);
    localStorage.setItem('userType', userType);
}

  const logFormData = () => {
    console.log("Image:", selectedImage);
//    console.log("Site Address:", siteAddress);
//    console.log("State:", state);
    console.log("Zipcode:", zipcode);
  };

  useEffect(() => {
    console.log('manufacturerName changed:', manufacturerName);
  }, [manufacturerName]);

  useEffect(() => {
    // if (finalSubmission && resultsRef.current) {
    //     window.scrollTo({
    //         top: resultsRef.current.offsetTop,
    //         behavior: "smooth"
    //     });
    // }
    if (productData.length > 0 && productResultsRef.current) {
      window.scrollTo({
          top: productResultsRef.current.offsetTop,
          behavior: "smooth"
      });
  }
  }, [finalSubmission, productData]);

  const handleImageSubmit = useCallback(async (event) => {
    event.preventDefault();
    console.log('manufacturerName:', manufacturerName);
    setProductData([]);
    const formData = new FormData();
    const filename = selectedImage.name;
    //formData.append('image', selectedImage, selectedImage.name);
//    formData.append('site_address', siteAddress);
//    formData.append('state', state);
    formData.append('zipcode', zipcode);
    formData.append('manufacturer_name', manufacturerName);
    const reader = new FileReader();
    if (manufacturerName) {
      setShowManufacturer(true);
    }

    const accessToken = localStorage.getItem('access');

    reader.onload = async function (event) {
      try {
        setIsLoading(true);
        setFinalSubmission(true);
        // Call the logFormData function before making the API request
        logFormData();
        const compressedData = pako.gzip(event.target.result);
        const blob = new Blob([compressedData], { type: 'application/octet-stream' });
        formData.append('image', blob, selectedImage.name);
        const url1 = `uploaddesign/${filename}`;
        const url2 = `uploaddesignmanufacture/${filename}`;
        let url = '';
        if (manufacturerName != '') {
          console.log("Enter section for manufacturer");
          url = url2;
          handleUserTypeSelection("Manufacturer")();
        }
        else {
          console.log("Enter section for general contractor");
          url = url1;
          handleUserTypeSelection("General Contractor")();
        }
        console.log(url)
        const response = await postDataWithAuth(url, formData, csrfToken, accessToken);
        if (response.data && response.data.response_content) {
              console.log(response.data);
              console.log('here');
              console.log(response.data.response_content);
              setProductData(response.data.response_content);
          }
        if (response.data && response.data.category_and_product_info) {
          setCategoryData(response.data.category_and_product_info);
          setCategoryDataLength(Object.keys(categoryData).length);
          console.log('category data');
          console.log(response.data.category_and_product_info);
        }
        console.log("Data Submitted");
        setIsFinished(true);
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setIsLoading(false);
      }
    };
    reader.readAsArrayBuffer(selectedImage);
  }, [csrfToken, selectedImage, zipcode, manufacturerName]);

  if (loading || !csrfToken) return null;

  return (
    <div className="design-container">
      <h2>Upload your Design / Drawing</h2>
      <input type="file" accept=".pdf" onChange={handleImageUpload} />

      <h4>Zipcode</h4>
      <input type="text" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />

      <h4>Manufacturer Firm Name (Optional)</h4> {/* New field */}
      <select
        value={manufacturerName}
        onChange={(e) => setManufacturerName(e.target.value)}
      >
        <option value="">Select Manufacturer</option> {/* Default option */}
        <option value="Hilti">Hilti</option>
        <option value="Holcim">Cemex</option>
      </select>
      {/* <input type="text" value={manufacturerName} onChange={(e) => {
        console.log('Input onChange:', e.target.value);
        setManufacturerName(e.target.value);
        }} 
      /> */}

      <button onClick={(event) => handleImageSubmit(event)}>Upload</button>

      {isLoading && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0px',
              left: '0px',
              display: 'flex',
              background: 'rgba(0, 0, 0, 0.64)',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '27px',
            }}
          >
            {/* Your loading indicator here */}
            Loading...
          </div>
        )}

      {/* {finalSubmission && productData.length === 0 && <h3 ref={resultsRef} className="loading-results">Analyzing results...</h3>} */}

      {productData.length > 0 && selectedUserType === 'General Contractor' && (
        <div className="products-list" ref={productResultsRef}>
            <ProductTable products={productData} showManufacturer={showManufacturer} />
        </div>
      )}
      {productData.length > 0 && selectedUserType === 'Manufacturer' && (
        <div className="products-list" ref={productResultsRef}>
          <ProductTableManufacturer categoryAndProductInfo={categoryData} />
        </div>
      )}
    </div>
  );
};

export default Design;
