import React, { Component } from 'react';
import "../styles/CTA.css";

function CTA2() {
    const dataArray = ['Connectors', 'Anchors', 'Epoxy Adhesives', 'Holddowns', 'Shearwalls', 'Strong Walls', 'Adhesives','Fasteners', 'Sealants', 'Moment Frames', 'Bracing Systems', 'Insulation'];

    return ( 
      <div className="cta2">
        <div className="cta2-header">
          Ready For The Future Of Smarter Construction?
        </div>
        <div className="cta2-text">
          Sign Up today and behold the AI-powered qualification tool for your new construction project to understand the requirements quickly.
        </div>
        <div className="cta2-signup-button">
          <a href="/signup" className="signup-button2">Sign Up</a>
        </div>
      </div>
    );
  }
  
  export default CTA2;