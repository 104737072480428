import React, { useState, useEffect, useRef } from 'react';
import "../styles/chatbotwidget.css";
import { useCallback } from "react";
import { useFetch } from "../hooks/useFetch";
import { postData } from "../utils/api";

function ChatbotWidget({ response, isOpen, toggleChatbot, view }) {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [isTextBoxEnabled, setIsTextBoxEnabled] = useState(true);

    const messagesEndRef = useRef(null);

    const { data: { csrfToken }, loading } = useFetch('get-csrf-token');

    useEffect(() => {
        if (response && isOpen) {
            setIsWaitingForResponse(false);
            if(typeof response === "object" && response.text){
                setMessages(prevMessages => [
                    ...prevMessages,
                    response
                ]);
            } else {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { type: 'bot', text: response }
                ]);
            }
        }
    }, [response]);

    useEffect(() => {
        if (isOpen && messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [isOpen, messages]);    

    const handleSend = useCallback(async (inputValue) => {
    let sendData = {};
    console.log("csrf token");
    console.log(csrfToken);
    try {
        let chatbotResponse = null;
        if (inputValue.trim()) {
            setMessages(prevMessages => [
                ...prevMessages,
                { type: 'user', text: inputValue },
                { type: 'bot', text: "One moment..." }
            ]);
            setIsWaitingForResponse(true);
            setIsTextBoxEnabled(false);
            sendData = { "flag": "false", "value": inputValue };
            if (view === "Architect/Engineer") {
                console.log("You are an architect/engineer - chatbot_project_based")
                chatbotResponse = await postData('chatbot_project_based', sendData, csrfToken);
            }
            else {
                chatbotResponse = await postData('chatbot', sendData, csrfToken); 
            }
            console.log("Response from chatbot");
            console.log(chatbotResponse);

            setMessages(prevMessages => [
                ...prevMessages,
                { type: 'bot', text: chatbotResponse.data["formatted_responses"] }
            ]);
            setInputValue('');
        }
    } catch (error) {
        console.error(error);
        setMessages(prevMessages => [
            ...prevMessages,
            { type: 'bot', text: "There was an issue. Please try again or enter a different question." }
        ]);
        setIsTextBoxEnabled(true);
    } finally {
        setIsWaitingForResponse(false); // Reset the flag after handling the response
        setIsTextBoxEnabled(true);
      }
    }, [csrfToken]);

    return (
        <div className={`chatbot-container ${isOpen ? 'open' : ''}`}>
            <div className="chatbot-header" onClick={toggleChatbot}>Chatbot</div>
            {isOpen && (
                <>
                    <div className={`messages ${isOpen ? 'open' : ''}`} ref={messagesEndRef}>
                        {/* {messages.map((message, index) => (
                            <div key={index} className={`message ${message.type}`}>
                                <strong>{message.type === 'user' ? 'Me:' : 'SkyGuide:'}</strong> <br /> {message.text}
                            </div>
                        ))} */}
                        {messages.map((message, index) => (
                            <div key={index} className={`message ${message.type}`}>
                                <strong>{message.type === 'user' ? 'Me:' : 'SkyGuide:'}</strong> <br />
                                {message.isHtml ? (
                                    <span dangerouslySetInnerHTML={{ __html: message.text }} />
                                ) : (
                                    message.text
                                )}
                            </div>
                        ))}
                        <div ref={messagesEndRef}></div>
                    </div>
                    <div className={`input-container ${isOpen ? 'open' : ''}`}>
                        <textarea
                            className="input"
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            placeholder="Type your message..."
                            rows={5}
                            onKeyDown={e => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSend(inputValue);
                                }
                            }}
                            disabled={isWaitingForResponse || !isTextBoxEnabled}
                        />
                        <button onClick={() => handleSend(inputValue)}>Send</button>
                    </div>
                </>
            )}
        </div>
    );
}

export default ChatbotWidget;
