import React from 'react';
import { useHistory } from 'react-router-dom';

function CustomAlert() {
    const history = useHistory();
  
    const handleOkClick = () => {
      history.push("/select-product");
    };

    const modalStyle = {
      width: '300px',
      padding: '20px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      background: '#143B5D',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      borderRadius: '5px'
    };

    const buttonStyle = {
        backgroundColor: 'black',
        color: 'white',
        fontSize: '16px',
        /* padding: 10px 60px; */
        borderRadius: '5px',
        margin: '10px 0px',
        cursor: 'pointer'
    };
  
    return (
      <div style={modalStyle}>
        <p>Please check your email to verify your account. Then you may login.</p>
        <button style={buttonStyle} onClick={handleOkClick}>Ok</button>
      </div>
    );
  }

  export default CustomAlert;
