import React, { Component } from 'react';
import "../styles/legal.css";

function PrivacyPolicy() {
    return (
        <div className="legal">
            <h1>Privacy Policy</h1>
            <h2 className="section-title">1. Introduction:</h2>
            <p>We at SkyBuild AI respect your privacy and are committed to protecting your personal data. This Privacy Policy will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>

            <h2 className="section-title">2. What Data We Collect:</h2>
            <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
            <ul>
                <li>Identity Data includes first name, last name, username or similar identifier.</li>
                <li>Contact Data includes email address.</li>
                <li>Technical Data includes internet protocol (IP) address, browser type and version, time zone setting and location, and other technology on the devices you use to access this website.</li>
            </ul>

            <h2 className="section-title">3. How We Use Your Personal Data:</h2>
            <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
            <ul>
                <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
                <li>Where it is necessary for our legitimate interests.</li>
                <li>We use the OpenAI ChatGPT API to generate personalized content and recommendations for our users. The ChatGPT API is a large language model that is trained on a massive dataset of text and code. It can generate text, translate languages, write different kinds of creative content, and answer your questions in an informative way. We use the ChatGPT API to generate personalized content and recommendations for you based on your current and past interactions with our website and app.</li>
            </ul>

            <h2 className="section-title">4. Data Security:</h2>
            <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed.</p>

            <h2 className="section-title">5. Your Legal Rights:</h2>
            <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data including the right to request access, correction, erasure, restriction, transfer, to object to processing, to portability of data and (where the lawful ground of processing is consent) to withdraw consent.</p>

            <h2 className="section-title">6. Contact Us:</h2>
            <p>If you have any questions about this privacy policy or our privacy practices, please contact us at notifications@skybuild.ai.</p>

            <h2 className="section-title">7. Changes to the Privacy Policy:</h2>
            <p>We reserve the right to update this privacy policy at any time, and we will provide you with a new privacy policy when we make any substantial updates.</p>
        </div>
    );
}

export default PrivacyPolicy;
