import React from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import features_background from '../images/features_background.jpg'; // Import your background image

const boxData = [
  {
    title: '1. Upload',
    description: 'Upload your blueprint.',
  },
  {
    title: '2. Analyze',
    description: 'We read, analyze, and cross-check the blueprints.',
  },
  {
    title: '3. Report',
    description: 'Receive a tailored report specifying the project scope and details.',
  },
  {
    title: '4. Q&A',
    description: 'Still have burning questions? Ask our AI assistant any technical question. Make your decision in minutes to pursue the project.'
  }
];

const Benefits2 = () => {
  return (
    <div
      style={{
        minHeight: 'auto', // Ensure the background covers the entire viewport height
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column', // Center content vertically and add text above the grid
        justifyContent: 'center',
      }}
    >
      <Typography variant="h4" style={{ fontSize: '5.5vh', fontWeight: 'bold', color: 'black', marginTop: '5%', marginBottom: '5%' }}>
        How It Works
      </Typography>
      <Container maxWidth="md" style={{ marginBottom: '5%'}}>
        <Grid container spacing={1}>
          {boxData.map((box, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Paper elevation={3} style={{ padding: '7%', minHeight: '35vh', textAlign: 'center', backgroundColor: '#143B5D' }}>
                <Typography variant="h4" color="white" marginBottom="3%">{box.title}</Typography>
                <Typography variant="body1" color="white" textAlign="left">{box.description}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Benefits2;
