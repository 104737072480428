import React, { useState, useCallback, useEffect } from 'react';
import "../styles/producttablemanufacturer.css";
import ChatbotWidget from './ChatbotWidget';

const ProductTableManufacturer = ({ categoryAndProductInfo}) => {
    const [userEmail, setUserEmail] = useState('');
    const [chatbotOpen, setChatbotOpen] = useState(false);
    const [chatbotResponse, setChatbotResponse] = useState("");
    const [userType, setUserType] = useState("Manufacturer");
    const env_score =  ["Very Good", "Good", "Fair"];

    useEffect(() => {
        setUserEmail(localStorage.getItem('userEmail'));
    }, []);

    const toggleChatbot = () => {
        setChatbotOpen(!chatbotOpen);
    };

    const formatProductsToString = (categoryAndProductInfo) => {
        let result = "";
    
        for (let category in categoryAndProductInfo) {
            result += `${category}\n`;
            categoryAndProductInfo[category].forEach(product => {
                result += `Name: ${product.name} | Usecase: ${product.usecase}\n`;
            });
            result += '\n\n';
        }
    
        return result;
    };

    const handleGenerateReport = () => {
        // Construct the email body. Assuming products is an array of strings.
        const emailBody = formatProductsToString(categoryAndProductInfo);
      
        // Create the mailto link
        const mailtoLink = `mailto:${userEmail}?subject=SkyBuild AI - Bill of Materials Report&body=${encodeURIComponent(emailBody)}`;
      
        // Open the user's email client
        window.location.href = mailtoLink;
      };

    return (
        <div>
            {Object.keys(categoryAndProductInfo).map(category => (
                <div key={category} className="category-section2">
                    <h2 className="rec-title2">{category}</h2>
                    <table className="product-table2">
                        <thead>
                            <tr>
                                <th>Name</th>
                                {/* <th>Environmental Score</th> */}
                                <th>Use Case</th>
                                {/* <th>Quantity</th>
                                <th>Purchase</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {categoryAndProductInfo[category].map(product => (
                                <tr key={product.name}>
                                    <td>{product.name}</td>
                                    {/* <td>{env_score[Math.floor(Math.random() * env_score.length)]}</td> */}
                                    <td>{product.usecase}</td>
                                    {/* <td></td>
                                    <td><button className="buy-product2">Purchase</button></td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
            <div className="generate-report">
                <h3>Generate a report of Bill of Materials</h3>
                <button className="generate-report-button" onClick={handleGenerateReport}>Generate Report</button>
            </div>
            {/* <div>
                <ChatbotWidget response={chatbotResponse} isOpen={chatbotOpen} toggleChatbot={toggleChatbot} view={userType}/>
            </div> */}
        </div>
    );
}

export default ProductTableManufacturer;
