import React from 'react';
import "../styles/legal.css";

function TermsOfConditions() {
    return (
        <div className="legal">
            <h1>Terms and Conditions</h1>

            <h2 className="section-title">1. Introduction:</h2>
            <p>These terms and conditions outline the rules and regulations for the use of SkyBuild AI's Website. By accessing this website we assume you accept these terms and conditions in full. Do not continue to use SkyBuild AI's website if you do not accept all of the terms and conditions stated on this page.</p>

            <h2 className="section-title">2. License:</h2>
            <p>Unless otherwise stated, SkyBuild AI and/or it’s licensors own the intellectual property rights for all material on SkyBuild AI. All intellectual property rights are reserved.</p>

            <h2 className="section-title">3. User Comments:</h2>
            <ul>
                <li>Certain parts of this website offer the opportunity for users to post comments. SkyBuild AI does not filter, edit, publish or review Comments prior to their appearance on the website.</li>
                <li>Comments do not reflect the views or opinions of SkyBuild AI, its agents or affiliates.</li>
                <li>Comments reflect the view and opinion of the person who posts such view or opinion.</li>
            </ul>

            <h2 className="section-title">4. Disclaimer:</h2>
            <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website.</p>

            <h2 className="section-title">5. Governing Law & Jurisdiction:</h2>
            <p>These terms and conditions are governed by and construed in accordance with the laws of San Francisco and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
        </div>
    );
}

export default TermsOfConditions;
