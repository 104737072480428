import React, { Component } from 'react';
import PlanGPTForm from "./PlanGPTForm";

function PlanGPT() {
    return ( 
      <div className="plangpt">
        <PlanGPTForm />
      </div>
    );
  }
  
  export default PlanGPT;
  