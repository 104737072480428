import {useEffect, useState} from "react";

import {getData, getUserData} from "../utils/api";

export function useFetch(endpoint){
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState({});

  useEffect(()=> {
    getData(endpoint)
      .then((res) => setData(res.data))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  },[endpoint]);

  return {
    loading,
    data
  }
}

export function useFetchForUser(endpoint, auth){
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState({});

  useEffect(()=> {
    getUserData(endpoint, auth)
      .then(res => res.data)
      .then(data => setData(data))
      .catch(error => {
        console.error('Error fetching user data:', error);
      })
      .finally(() => setLoading(false));
  },[endpoint, auth]);

  return {
    loading,
    data
  }
}
