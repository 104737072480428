import React from 'react';
import { Redirect } from 'react-router-dom';
import "../styles/loading.css";

const Loading = ({ isAuthenticated }) => {
    if (!isAuthenticated) {
        return <Redirect to="/login" />;
    }

    return (
        <div className="loading-container">
            <div className="loading-spinner"></div>
            <div className="loading-text">Loading...</div>
        </div>
    );
}

export default Loading;
