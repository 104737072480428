import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Loading from "./Loading";

const ProtectedRoute = ({ component: Component, isAuthenticated, isAuthenticating, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        console.log("Rendering ProtectedRoute with:", { isAuthenticated, isAuthenticating });
        if (isAuthenticating) {
          return <Loading isAuthenticated={isAuthenticated} />;
        } else if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated, // Replace with your actual path to the authentication state
  isAuthenticating: state.auth.isAuthenticating  // Replace with your actual path to the isAuthenticating state
});

export default connect(mapStateToProps)(ProtectedRoute);


// const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={props =>
//         isAuthenticated ? (
//           <Component {...props} />
//         ) : (
//           <Redirect to="/login" />
//         )
//       }
//     />
//   );
// };

// const mapStateToProps = state => ({
//   isAuthenticated: state.auth.isAuthenticated // Replace `state.auth.isAuthenticated` with your actual path to the authentication state
// });

// export default connect(mapStateToProps)(ProtectedRoute);
