import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useState, useEffect } from 'react';
import "../styles/feedbackpopup.css";
import { useHistory } from 'react-router-dom';


function FeedbackPopup({ text, trigger, setTrigger, url }) {
  const history = useHistory();

  return (
    <Popup open={trigger} onClose={() => setTrigger(false)}>
      <div className="feedback-popup">
        <p className="feedback-popup-text">{text}</p>
        <button className="ok-button" onClick={() => {
            setTrigger(false);
            history.push(url);
        }}>Ok</button>
      </div>
    </Popup>
  );
}

export default FeedbackPopup;

