import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { signup } from '../actions/auth';
import axios from 'axios';
import CustomAlert from './CustomAlert';
import FeedbackPopup from './FeedbackPopup';
import { useHistory } from 'react-router-dom';
import "../styles/authentication.css";

const Signup = ({ signup, isAuthenticated }) => {
    const [accountCreated, setAccountCreated] = useState(false);
    const [formError, setFormError] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");
    const [url, setUrl] = useState("/signup");
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        re_password: ''
    });

    const { first_name, last_name, email, password, re_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const history = useHistory();

    useEffect(() => {
        console.log("Is the user signed up?");
        console.log(isAuthenticated);
        if (isAuthenticated) {
            history.push("/select-product");
        }
    }, [isAuthenticated]);

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    };

    const resetFormData = () => {
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            re_password: ''
        });
    };

    const onSubmit = async e => {
        e.preventDefault();
    
        if (password === re_password && isValidEmail(email)) {
            const response = await signup(first_name, last_name, email, password, re_password);
            console.log("response.status for sign up");
            console.log(response.status);  // This will log the status code
            console.log("response.data for sign up");
            console.log(response.data);   // This will log the data
            if (response.status >= 200 && response.status <= 205) {
                setUrl("/login");
                setFeedbackText("Please check your email to verify your account. Then you may login.");
                setAccountCreated(true);
            }
            else if ("email" in response.data && response.data.length > 1) {
                response.data["email"][0].includes("this email already exists");
                setUrl("/login");
                setFeedbackText("You are already signed up!");
                setFormError(true);
            }
            else {
                setUrl("/signup");
                let error_data = "";
                if (response.data.password && Array.isArray(response.data.password)) {
                    error_data = response.data.password.map(element => error_data + element).join('');
                }
                else {
                    error_data = "There was an issue. Please try again with a different password.";
                }
                setFeedbackText(error_data);
                setFormError(true);
            }
        }
        else if (password != re_password) {
            setUrl("/signup");
            setFeedbackText("Please enter the same password for both fields.");
            setFormError(true);
            resetFormData();
        }
        else if (!isValidEmail(email)) {
            setUrl("/signup");
            setFeedbackText("Please enter a valid email address.");
            setFormError(true);
            resetFormData();
        }
        else {
            setUrl("/signup");
            setFeedbackText("An error occurred. Please try again.");
            setFormError(true);
            resetFormData();
        }
    };  

    const continueWithGoogle = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}/google`)

            window.location.replace(res.data.authorization_url);
        } catch (err) {

        }
    };

    const continueWithFacebook = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_API_URL}/facebook`)

            window.location.replace(res.data.authorization_url);
        } catch (err) {

        }
    };

    // if (isAuthenticated) {
    //     return (
    //         <div>
    //           <CustomAlert />
    //         </div>
    //       );
    // }
    // if (accountCreated) {
    //     return (
    //         <div>
    //           <CustomAlert />
    //         </div>
    //       );
    // }

    return (
        <div className='container mt-5'>
            <h1>Sign Up</h1>
            <p>Create your Account</p>
            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='text'
                        placeholder='First Name*'
                        name='first_name'
                        value={first_name}
                        onChange={e => onChange(e)}
                        required
                    />
                </div>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='text'
                        placeholder='Last Name*'
                        name='last_name'
                        value={last_name}
                        onChange={e => onChange(e)}
                        required
                    />
                </div>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='email'
                        placeholder='Email*'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                    />
                </div>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='Password*'
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                </div>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='Confirm Password*'
                        name='re_password'
                        value={re_password}
                        onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                </div>
                <p>
                    By registering, you are agreeing to our
                    <Link to="/privacy-policy"> Privacy Policy </Link> 
                    and
                    <Link to="/terms-and-conditions"> Terms and Conditions</Link>.
                </p>
                <button className='btn btn-primary' type='submit'>Register</button>
                {formError && <FeedbackPopup text={feedbackText} trigger={formError} setTrigger={setFormError} url={url} />}
                {accountCreated && <FeedbackPopup text={feedbackText} trigger={accountCreated} setTrigger={setAccountCreated} url={url} />}
            </form>
            {/* <button className='btn btn-danger mt-3' onClick={continueWithGoogle}>
                Continue With Google
            </button>
            <br />
            <button className='btn btn-primary mt-3' onClick={continueWithFacebook}>
                Continue With Facebook
            </button> */}
            <p className='mt-3'>
                Already have an account? <a href="/login">Sign In</a>
            </p>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { signup })(Signup);