import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/auth';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import FeedbackPopup from './FeedbackPopup';
import "../styles/authentication.css";

const Login = ({ login, isAuthenticated }) => {
    const [formError, setFormError] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");
    const [url, setUrl] = useState("/login");
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const resetFormData = () => {
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            re_password: ''
        });
    };

    const { email, password } = formData;

    localStorage.setItem('userEmail', email);

    const history = useHistory();

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    useEffect(() => {
        console.log("Is the user logged in?");
        console.log(isAuthenticated);
        if (isAuthenticated) {
            history.push("/select-product");
        }
    }, [isAuthenticated]);

    const onSubmit = async e => {
        e.preventDefault();

        const response = await login(email, password);
        console.log(response)
        if (response.status != 200) {
            setUrl("/login");
            setFeedbackText("The email address and password do not match.");
            setFormError(true);
        }
    };

    const continueWithGoogle = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}/google`)

            window.location.replace(res.data.authorization_url);
        } catch (err) {

        }
    };

    const continueWithFacebook = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_API_URL}/facebook`)

            window.location.replace(res.data.authorization_url);
        } catch (err) {

        }
    };

    // if (isAuthenticated) {
    //     window.location.replace("/select-product");
    // }

    return (
        <div className='centered-container'>
            <div className='container mt-5'>
                <h1>Sign In</h1>
                <p>Sign into your Account</p>
                <form onSubmit={e => onSubmit(e)}>
                    <div className='form-group'>
                        <input
                            className='form-control'
                            type='email'
                            placeholder='Email'
                            name='email'
                            value={email}
                            onChange={e => onChange(e)}
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <input
                            className='form-control'
                            type='password'
                            placeholder='Password'
                            name='password'
                            value={password}
                            onChange={e => onChange(e)}
                            minLength='6'
                            required
                        />
                    </div>
                    <button className='btn btn-primary' type='submit'>Login</button>
                    {formError && <FeedbackPopup text={feedbackText} trigger={formError} setTrigger={setFormError} url={url} />}
                </form>
                {/* <button className='btn btn-danger mt-3' onClick={continueWithGoogle}>
                    Continue With Google
                </button>
                <br />
                <button className='btn btn-primary mt-3' onClick={continueWithFacebook}>
                    Continue With Facebook
                </button> */}
                <p className='mt-3'>
                    Don't have an account? <a href="/signup">Sign Up</a>
                </p>
                <p className='mt-3'>
                    Forgot your Password? <a href="/reset-password">Reset Password</a>
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);